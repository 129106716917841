.everyCount {
  justify-content: center;
  display: flex;
  width: 9%;
  height: calc(1.2em + 0.6rem + 2px);
  padding: .375rem 0rem 0.3rem 0.3rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111827;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #a0a3a9;
  border-radius: 0.25rem;
}

.everyCount::-webkit-outer-spin-button,
.everyCount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.everyCount-2 {
  justify-content: center;
  display: flex;
  height: calc(1.2em + 0.6rem + 2px);
  padding: 0.375rem 0.7rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #111827;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #a0a3a9;
  border-radius: 0.25rem;
}

.everyCount-2::-webkit-outer-spin-button,
.everyCount-2::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.everyCount:disabled {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  box-shadow: none;
  opacity: 1;
}

.everyYear {
  display: block;
  width: 35%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #6C4CDE;
  border-radius: 0.25rem;
  box-shadow: #6C4CDE 0px 0px 3px;
}

.everyYear:disabled {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  box-shadow: none;
  opacity: 1;
}

.everyCountText {
  margin: 0 0.3rem;
}

#monthlySelection {
  .monthlyOptions .picky__input {
    border-color: #d0d5d8;
    overflow: hidden;
    width: 100%;
    height: 44px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;
  }

  // .monthlyOptions .picky__dropdown .option input
  // {
  // display: inline;
  // border-radius: 2rem;
  // }
  .picky__dropdown .option {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    font-size: 14px;
    border-bottom: none;
  }

  .picky__dropdown .option.selected {
    background-color: none;
    border-bottom: none;

  }

  .picky__dropdown .option:focus,
  .picky__dropdown .option:hover,
  .picky__dropdown .option.selected {
    outline: none !important;
    color: #50D7ED;
    background: var(--color-primary-blue-light);
    border: none;
  }

  .picky__filter__input {
    font-size: 14px;
    font-family: "Open Sans" !important;

  }

  // .picky__dropdown .option input
  // {
  // display: none;
  // }
  .picky__placeholder {
    color: #595959;
    font-size: 14px;
  }

  .picky__input::after {
    width: 5px !important;
    height: 5px !important;
    border-left: 1px solid #595959 !important;
    border-bottom: 1px solid #595959 !important;
    transform: rotate(-45deg);
    position: absolute;
    margin-left: 2px;
    vertical-align: middle;
    font-family: FontAwesome!important;
    content: ""!important;
    border-top: none;
    border-right: none;
    // border-left: none;
    right: 8px !important;
    top: 16px;
  }

  .picky__dropdown .option.selected {
    background-color: #fff;
    color: #000;
    border: none;
  }

  .forcedDisabled .picky__input {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.CustomLabel {
  .BlackLabel {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px;
    line-height: 140% !important;
    color: #8a8e95 !important;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem!important;
  }
}

#summary {

  .Calendar {
    height: 17px;
    width: 17px;
  }

  .keyBox {
    background-color: #6C4CDE;
    width: 3px !important;
  }

  .bgColor {
    display: flex;
    align-items: center;
    background-color: #f8f6fe;
    color: #50D7ED;
    font-size: 14px;
    border: 1px solid #f8f6fe;

    border-radius: 6px;

    cursor: context-menu;
    width: 100%;
  }
}

.MonitoringMaxheigth {
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ScrollableDiv {
  min-height: 185px;
  max-height: 68vh;
  overflow-x: hidden;
  overflow-y: auto;
  // #single-select-dp .status-div-dropdown {
  //   position:revert;
  //   // top: auto;
  //   margin-top: 10px;
  // }
}

.EditScheduleMinHeight {
  min-height: 330px !important;
}

.ScrollableDiv::-webkit-scrollbar {
  height: 8px;
  width: 5px;
}

/* Track */
.ScrollableDiv::-webkit-scrollbar-track {
  background: #fff;
}
.ScrollableDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Handle */

.weekday-custom {
  display: none;
}

.WeekdayLabel {
  color: #000000;
  display: flex;
  background-color: #f1f1f1;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.WeekdayChecked {
  background-color: #50D7ED !important;
  color: #fff;
}

.CalendarDropdown {
  height: auto !important;
  padding: 10px 15px !important;
  border: none !important;
  font-size: 12px !important;

  .add-user-div-dropdown-content {
    font-size: 12px !important;
    height: auto !important;
  }

  .status-div-dropdown {
    max-height: 200px !important;
  }

  .fa-angle-down,
  .fa-angle-up {
    color: #50D7ED;
  }
}

.CalendarIcon {

  z-index: 10;
  right: 10px;
  top: 12px;
  position: absolute;
  height: 18px;
  width: 18px;

}
@media screen and (max-width: 1500px){
.CalendarIcon {
    z-index: 10;
    right: 10px;
    top: 10px;
    position: absolute;
    height: 18px;
    width: 18px;
}
}
.mt2pt5rem {
  margin-top: 2rem !important;
}

@media screen and (max-width: 1500px) {
  #monthlySelection {
    .monthlyOptions .picky__input {
      height: 38px;
      padding: 8px 15px;
    }

    .picky__input::after{
      top: 14px;
    }
  }
}
.react-datepicker{
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
}