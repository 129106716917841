#downloaprofileresponsetable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #6C4CDE;
    color: white;
}

#downloaprofileresponsetable td, #downloaprofileresponsetable th {
    border: 1px solid #ddd;
    padding: 8px;
}
#downloaprofileresponsetable td, #downloaprofileresponsetable th {
    border: 1px solid #ddd;
    padding: 8px;
}