.modal-open .modal{
  backdrop-filter: blur(10px) !important;
}

#NewPopup {
  min-width: min-content;

  .modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */
    color: #111827;
  }

  .modal-header {
    border: none;
  }

  .close {
    font-weight: 500 !important;
    opacity: 1;
    span{
      background-color: #F0F0F0
      ;
      border-radius: 50%;
      color: #111827;

      // padding: 5px;
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1500px) {
    .modal-title{
      font-size: 18px;
    }
  }
  
}