a {
  font-weight: 600;
}

.page-title-box h4 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px !important;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #3b7ddd;
  border-color: #3b7ddd;
}

.h-44 {
  height: 44px;
}

.picky__filter__input {
  background-image: url('../assets/images/icons/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: 5px center;
  padding-left: 25px !important;
}

.blue-dot {
  --size: 10px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  border-radius: 9999px;
  background: var(--color-primary-blue);
}

.bg-success {
  background-color: #00c9a7 !important;
}

.bg-warning {
  background-color: #f5ca99 !important;
}

.bg-danger {
  background-color: #ed4c78 !important;
}

.bg-blue {
  background-color: #0091FF !important;
}

.bg-default {
  background-color: #6B6B6B !important;
}

.width-20 {
  width: 20% !important;
}

.width-30 {
  width: 30% !important
}

.width-40 {
  width: 40% !important;
}

.width-60 {
  width: 60% !important;
}

.width-80 {
  width: 80% !important;
}

.width-50 {
  width: 50% !important;
}

.width-90 {
  width: 90% !important
}


.op-1 {
  opacity: 0.1;
}

.op-25 {
  opacity: 0.25;
}

.op-5 {
  opacity: 0.5;
}

.op-75 {
  opacity: 0.75;
}


.f-8 {
  font-size: 8px;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px;
}

.f-26 {
  font-size: 26px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-50 {
  font-size: 50px;
}

h2 {
  font-size: 32px !important;
}

@media (min-width : 1420px) and (max-width : 1599px) {
  .ReportExcel {
    .custom-control-label::after {
      position: absolute;
      top: 0.28rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50%/50% 50% no-repeat;
    }
  }
}

@media (min-width : 1600px) and (max-width : 1699px) {
  .ReportExcel {
    .custom-control-label::after {
      position: absolute;
      top: 0.26rem;
      left: -1.491rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50%/50% 50% no-repeat;
    }
  }

  .ReportCase {
    .custom-control-label::after {
      position: absolute;
      top: 0.2623rem;
      left: -1.52rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50%/50% 50% no-repeat;
    }
  }
}

@media (min-width : 1700px) and (max-width : 1900px) {
  .ReportCase {
    .custom-control-label::after {
      position: absolute;
      top: 0.2735rem;
      left: -1.48rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50%/50% 50% no-repeat;
    }
  }
}

.filterSelectedValue {
  border: 1px solid #50D7ED !important;
  color: #50D7ED;
}

.deleteBatchCase {
  .modal-title {
    font-size: 18px !important;
  }
}

.warning-modal {
  width: auto;
  background: #FBF4E7;
  color: #D29C35;
  border-left: 4px solid #EBAE39;
  padding: 8px;
  margin-top: 10px;

}

@media screen and (max-width: 1500px) {
  .f-8 {
    font-size: 6px;
  }

  .f-10 {
    font-size: 8px;
  }

  .f-12 {
    font-size: 10px;
  }

  .f-14 {
    font-size: 12px;
  }

  .f-15 {
    font-size: 13px
  }

  .f-16 {
    font-size: 14px !important;
  }

  .f-18 {
    font-size: 16px !important;
  }

  .f-20 {
    font-size: 18px;
  }

  .f-26 {
    font-size: 24px;
  }

  .f-30 {
    font-size: 28px;
  }

  .f-32 {
    font-size: 30px;
  }

  .f-50 {
    font-size: 48px;
  }

  h2 {
    font-size: 30px !important;
  }
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}


.br-2 {
  border-radius: 2px;
}

.br-4 {
  border-radius: 4px;
}

.br-6 {
  border-radius: 6px;
}

.br-8 {
  border-radius: 8px;
}

.v-divider {
  width: 1px;
  background-color: #c2c8d1;
}



.site-page {
  min-height: inherit;
  padding: 2rem;
  // opacity: 0.5;
  // animation: fade-in 450ms ease-out forwards;

  &__top-section {
    position: relative;

    .bg-world-map {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      filter: invert(93%) sepia(23%) saturate(114%) hue-rotate(192deg) brightness(91%) contrast(120%);

      background-image: url(../assets/images/misc/world-map.svg) !important;
      background-position: 50vw 36% !important;
      background-repeat: no-repeat !important;
      background-size: 65vw 65vw !important;
    }
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__heading {
    @include heading-styling;
    text-transform: uppercase;
  }

  &__sub-heading {
    @include sub-heading-styling;
  }
}

.MuiInputBase-input {
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0.67px !important;
  color: #a5a5a5 !important;
}

.MuiOutlinedInput-adornedStart {
  padding-left: 10px !important;
}

.tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px 11px;
  border: 1px solid var(--color-primary-blue);
  border-radius: 40px;
  font-size: 10px;
  color: var(--color-primary-blue);
  font-weight: 600;
}

path {
  transition: fill 1000ms ease-in-out !important;
}

// --------------------------------------
.react-switch-bg {
  background: var(--color-secondary-purple) !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #50D7ED;
  border-color: #50D7ED;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #50D7ED;
  border-color: #50D7ED;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.05);
}

.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--color-primary-blue);
  border-color: var(--color-primary-blue);
  outline: none;
}

.bluTxt {
  color: var(--color-primary-blue);
}

.errorMsg {
  color: red;
  font-size: 12px;
  font-weight: 400 !important;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px !important;
}

.form-control {
  // height: 34px;
  padding: 0.2rem 0.75rem;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid #11182733;
  background-color: #fff;
  outline: 0;
  // box-shadow: 0 0 0 0.2rem rgba(214, 214, 255, 7.5);
}


.cursor {
  cursor: pointer;
}

.download-popup-body {
  width: 520px;
  // min-height: 220px;
  // .pdfViewer {
  //   height: 0px;
  // }
}

.rdrMonthAndYearPickers select:hover {
  background-color: transparent;
}

.btn-primary,
.btn-primary-lite {
  font-size: 14px;
  padding: 0 24px;
  height: 44px;

  @media screen and (max-width: 1500px) {
    height: 38px;
    font-size: 12px;
  }
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary-lite:disabled,
.confirmButton:disabled {
  color: #fff;
  background-color: rgba(17, 24, 39, 0.20);
  border-color: transparent;
}

.btn-primary {
  border-color: #50D7ED;
}

.btn-primary-lite {
  background-color: #F8F6FE;
  border-color: #F8F6FE;
  color: #50D7ED;

  &:hover {
    color: #50D7ED;
  }
}

.px-15px {
  padding: 0px 15px;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #71748d;
}

input[type="file"]::file-selector-button {
  // font-size: 12px;
  border: 1px solid;
  border-radius: 4px;
  margin-top: 2px;
}

input[type="file"]::-webkit-file-upload-button {
  border: 1px solid #E9E8E8;
  background-color: #E9E8E8 !important;
  color: #111827;
}

input[type="file"] {
  color: #71748d;
  background-color: #fff;
  border-color: #11182733 !important;
  outline: 0;
}

input[type="file"] :hover {
  box-shadow: 0 0 0 0.1rem #d7e4ff;
}

.disable {
  background-color: #eeeeee !important;
}

.h-80vh {
  min-height: 80vh;
}

.New-Site-Page-Heading {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  /* or 39px */

  display: flex;
  align-items: center;

  /* Black / 500 */

  color: #111827;
}

//New Table Scss
.react-bootstrap-table {

  min-height: 251px;

  tbody {
    background-color: #ffffff;
  }

  .NewTable {
    background: #DBDEE4;
    flex: 1;

    /* Black / 100 */
    .customBG th {
      background: #F9FAFD !important;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      align-items: center;
      letter-spacing: 0.02em;
      // text-transform: uppercase;
      color: #687182;
      vertical-align: middle;

    }

    .expand-cell-header {
      pointer-events: none;
    }

    border: 1px solid #DBDEE4;
    border-radius: 8px;
    // overflow: hidden;
    margin-bottom: 1.2rem;

    tr:hover {
      background-color: var(--color-primary-blue-light);
    }


    tr td {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      vertical-align: middle;
      /* identical to box height, or 20px */
      color: #111827;
      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
    }

    // td,
    // th {
    //   border-top: 1px solid #DBDEE4;
    // }
  }
}

//New Page Css

#NewPageLayout {
  background-color: #F4F7FC;
  min-height: 100vh;

  // input {
  //   font-family: Inter;
  // }
  .page-item.active .page-link {
    background: var(--color-primary-blue-light);
    border: 1px solid #50D7ED;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */
    color: #50D7ED !important;
  }

  .page-item .page-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    // color: rgba(120, 84, 247, 0.8);
    color: #50D7ED;
    background-color: transparent;
    border: none;
    margin: 0px 2px;
  }

  .page-link:focus {
    box-shadow: none;
    z-index: 0;
  }

  .page-link:hover {
    box-shadow: none;
    z-index: 0;
  }

  .active .page-link:hover {
    z-index: 2;
  }
}

.newTabList {
  margin-top: 20px;

  .nav-link.active {
    background-color: transparent !important;
    color: #50D7ED !important;
    border-bottom: 2px solid #50D7ED !important;
    border-color: transparent;

    .TabCount {
      color: #50D7ED !important;
      // background-color: var(--color-secondary-blue);
      background-color: #f0e7f3;
    }

  }

  .TabCount {
    background-color: #e6e8eb;
    color: #767d8d;
    border-radius: 13px;
  }

  .nav-link:hover {
    border: none;
    font-weight: 600;
    border: 1px solid transparent;
  }
}

.back-icon {
  font-size: 16px;
  font-weight: 600;
  margin-right: 12px;
  cursor: pointer;
}

.Label {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  color: #8a8e95 !important;

}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
  color: #000;
}

.NewSelect {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #111827;
  border: 1px solid rgba(17, 24, 39, 0.4);
  // text-indent: 5px;
  // option {
  //   padding: 20px!important;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 140%;
  //   color: rgba(17, 24, 39, 0.8);
  //   mix-blend-mode: normal;
  // }
  // option:hover{
  //   background: rgba(120, 84, 247, 0.05)!important;
  //   color:  #50D7ED!important;
  // }
}

.NewSelect:focus {
  border: 1px solid rgba(17, 24, 39, 0.4);
  box-shadow: none;
}

#workspace,
#auditLogs,
#liveScanSearch,
#batchupload,
#trackerPage {
  input:-internal-autofill-selected {
    border-left: none;
    border-right: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.NewInput {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #111827 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 0 20px !important;
  height: 44px;

  &::placeholder {
    color: rgba(17, 24, 39, 0.40);
  }

  &::-ms-input-placeholder {
    color: rgba(17, 24, 39, 0.40);
  }

  @media screen and (max-width: 1500px) {
    padding: 0 15px !important;
    height: 38px;
    font-size: 12px !important;
  }
}

#NewInput {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #111827 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 12px 20px !important;
  height: 44px;
  font-family: "Open Sans";
  border-radius: 6px;
  border: 1px solid #11182733;
  background-color: #fff;
  outline: 0;

  &::placeholder {
    color: rgba(17, 24, 39, 0.40);
  }

  &::-ms-input-placeholder {
    color: rgba(17, 24, 39, 0.40);
  }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.select-file-form {
  height: 44px;
  padding: 7px 20px !important;
}

.dropdown-div-css {
  border: 1px solid rgba(17, 24, 39, 0.2) !important;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px !important;
}

.Asterisk {
  color: #dc3545;
}

#ct-container {
  .ct-toast {
    width: 428px;
    min-height: 78px;
    justify-content: start;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflowX-hidden {
  overflow-x: hidden !important;
}

.overflowY-hidden {
  overflow-y: hidden !important;
}

.overflowX-scroll {
  overflow-x: scroll !important;
}

.overflowY-scroll {
  overflow-y: scroll !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.height-100vh {
  height: 100vh;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.on-hover-underline {
  text-decoration: none;
  color: var(--color-primary-blue);

  &:hover {
    text-decoration: underline;
    color: var(--color-primary-blue);
  }
}



.bg-alice-blue {

  background-color: #F4F7FC;
}


.new_btn {
  min-width: 144px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0.25rem;
  height: 44px;

  &_border_blue {
    color: #50D7ED;
    border: 1px solid #50D7ED;
  }

  &_bg_blue {
    color: white;
    background-color: #50D7ED;
    border: 1px solid #50D7ED;
  }

  &_grey {
    cursor: not-allowed;
    background-color: white;
    color: rgba(17, 24, 39, 0.2);
    border: 1px solid rgba(17, 24, 39, 0.2);

  }

  &_empty_bg {
    color: #111827;
    border: none;
    background-color: white;
  }

  &_red_btn {
    color: white;
    background-color: #E55656;
    border: 1px solid #E55656;
  }

  &_red_btn:hover {
    color: white;
    background-color: #E55656 !important;
    border: 1px solid #E55656 !important;
  }

  &:hover {
    background-color: #50D7ED;
    color: white;
  }

  &:disabled {
    opacity: 0.65;
  }

  @media screen and (max-width: 1500px) {
    font-size: 12px;
    height: 38px;

  }
}

.new_btn:disabled {
  &:hover {
    color: #fff;
    background-color: rgba(17, 24, 39, 0.20);
    border-color: transparent;
  }
}

.size-d-v {
  width: 100px;
  min-width: unset;
}




.no-data-style {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  color: rgba(17, 24, 39, 0.6);
}


@media screen and (min-width: 1500px) {
  .no-data-style {
    height: 70vh;
  }
}

// .color-orange {
//   color: #D29C35;
// }

// .bg-orange {

// }

//Case view slider css
#CaseView {
  .MuiPaper-root {
    border: none;
  }

  .MuiAccordionSummary-content {
    margin: 0px;
  }

  .card-header {
    background-color: #F8F8F8;
    min-height: unset;
    height: 45px;
    display: flex;
    align-items: center;
  }

  .MuiIconButton-root {
    font-size: initial;
  }

  #CaseView {
    .header-text {
      font-weight: 500 !important;
      color: #000;
      // opacity: 0.6;
    }
  }

  .card-header .header-text {
    font-weight: 600;
    color: #000;
    // opacity: 0.6;
  }

  .picky__dropdown {
    max-height: 200px !important;
    position: absolute;
  }

  .user-info-box {
    padding: 0 !important;
  }

  .MuiAccordion-root:before {
    background-color: transparent;
  }
}

.header-text-New {
  position: absolute;
  top: 0;
  left: 12px;
  font-size: 22px;
  color: #111827;
  margin-top: 10px;
  font-weight: 600;

}

.accordion-arrow-new {
  transform: rotate(0deg) !important;
  width: 20px;
  height: 20px;
}

.disabledNew {
  padding: 10px 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 6px;
  height: 38px;
  box-shadow: none;
  cursor: default !important;
  background-color: transparent !important;
  border: 1px solid rgba(17, 24, 39, 0.2) !important;
  color: rgba(17, 24, 39, 0.2) !important;
}

.disable-pointer {
  pointer-events: none;
}

.reviewPageTab {
  .new-Input-Button {
    height: 38px !important;
  }
}

.modal-content {
  border: none;
  border-radius: 10px;

  .modal-header {
    padding: 32px 32px 0 32px;
  }

  .modal-body {
    padding: 10px 32px 32px 32px;
  }
}

.line-clamp {
  display: -webkit-box;
  display: -moz-box;
  display: -moz-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // white-space;
  word-break: break-word;
  // word-wrap: break-word; 
  overflow: hidden;
  visibility: visible;
}

.fa-new {
  color: #c2c8d1;
  height: 19px !important;
  width: 19px !important;
}

.input-button {
  .fa-new {
    color: #fff;
  }
}

.posABSWorkspaceButton {
  position: absolute;
  top: 70px;
  right: 0px;
}

.posABSWorkspaceButton-2 {
  position: absolute;
  top: 80px;
  right: 108px;
}

.modal-open {
  .posABSWorkspaceButton-2 {
    position: absolute;
    top: 80px;
    right: 118px;
  }

  .exportBTN {
    position: absolute;
    top: 80px;
    right: 372px;
  }
}

@media screen and (min-width: 1900px) {
  .modal-open {
    .posABSWorkspaceButton-2 {
      position: absolute;
      top: 80px;
      right: 118px;
    }

    .exportBTN {
      position: absolute;
      top: 80px;
      right: 372px;
    }
  }
}

@media (min-width:1024px) and (max-width: 1440px) {
  .modal-open {
    .posABSWorkspaceButton-2 {
      position: absolute;
      top: 80px;
      right: 118px;
    }
  }
}

.posABSWorkspaceButton-3 {
  position: absolute;
  top: 72px;
  right: 610px;
}

.posABSWorkspaceButton-4 {
  position: absolute;
  top: 80px;
  right: 8px;
}

.mt-0imp {
  margin-top: 0 !important;
}

.bg-color-blue-lite {
  background-color: #F4F7FC;
}

.new-Input-Button {
  .fa-new:hover {
    filter: brightness(0) saturate(100%) invert(57%) sepia(83%) saturate(5648%) hue-rotate(214deg) brightness(91%) contrast(92%)
  }

  .fa-new {
    filter: brightness(0) saturate(100%) invert(68%) sepia(88%) saturate(325%) hue-rotate(144deg) brightness(95%) contrast(96%);
  }
}

.custom-padding-btn {
  padding: 10px 45px !important;
}

.custom-checkbox-table {
  accent-color: #9747ff;
}

input[type=checkbox][disabled] {
  opacity: 0.75; // or whatever
}

.custom-dateRangePicker .date-range-picker__toggle-button {
  padding: 12px 20px !important;
  height: 44px;
  font-size: 14px;
}

.mui-radio {
  color: #50D7ED !important;
}

.Mui-checked:not(.Mui-disabled) {
  color: #50D7ED !important;
}

.Mui-checked {
  color: rgba(119, 84, 247, 0.582) !important;
}

.MuiFormControlLabel-label {
  color: #3f4450 !important
}

.NewScanImage {
  height: 20px;
  width: 20px;
}

.custom-radio {

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: var(--color-primary-blue);
    border-color: var(--color-primary-blue);
    box-shadow: none;
  }

  .custom-control-input:disabled~.custom-control-label::before {
    background-color: transparent;
  }
}

.react-datepicker-popper {
  z-index: 1010;
}

.minheight {
  min-height: auto !important;
}

.NewSelectDropdown {
  option {
    color: #1F2A55;
    font-size: 16px;
    cursor: pointer;
  }

}

.NewOption:hover {
  color: #50D7ED !important;
  background: rgba(120, 84, 247, 0.05) !important;
}

.newInputPadding {
  padding: 12px 20px !important;
  height: 45px;
}

.FileInputPadding {
  padding: 7px 20px !important;
}

input::file-selector-button {
  padding: 0 10px;
  background-color: var(--color-primary-blue);
  height: 24px;
  color: #ffffff;
  font-size: 11px;
  margin: 0;
  margin-right: 5px;
}

.custom-slider {
  padding: 10px 0 0 0 !important;

  .bar {
    height: 4px;
    width: 100%;

    .thumb::before {
      height: 18px !important;
      width: 18px !important;
      border: 1px solid #A4A4A4 !important;
      box-shadow: unset !important;
      margin: -6px -12px !important;
    }

    .bar-left,
    .bar-inner,
    .bar-right {
      box-shadow: unset !important;
      border: unset !important;
      padding: unset !important;
    }
  }

  .labels {
    margin-top: 5px !important;

    .label {
      font-size: 14px;
      font-weight: 700;

      &:first-child {
        color: #3CA774;
        margin-left: -9px;
      }

      &:last-child {
        color: #EA5D5D;
        margin-right: -8px;
      }
    }
  }

  .caption {
    display: flex !important;
    bottom: 20px !important;
    left: -7px !important;

    .min-caption,
    .max-caption {
      font-weight: 700;
      font-size: 14px !important;
      background-color: #fff !important;
      box-shadow: unset !important;
      color: #D2A157 !important;
      height: unset;
      min-width: unset !important;
    }
  }

  &.n-check-t {

    .bar-left,
    .bar-inner,
    .bar-right {
      background-color: #BFBFBF !important;
    }

    .label {
      &:first-child {
        color: #8F8F8F;
      }

      &:last-child {
        color: #8F8F8F;
      }
    }

    .min-caption,
    .max-caption {
      color: #8F8F8F !important;
    }
  }
}



.bar {
  height: 10px;
  width: 120px;
  // padding: 10px;
  // margin: 200px auto 0;
  /* background-color: rgba(0,0,0,.1); */
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  /* -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.03), inset 0 1px 0 rgba(0,0,0,.1); */
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .03), inset 0 1px 0 rgba(0, 0, 0, .1);
  -ms-box-shadow: 0 1px 0 rgba(255, 255, 255, .03), inset 0 1px 0 rgba(0, 0, 0, .1);
  /* box-shadow: 0 1px 0 rgba(255,255,255,.03), inset 0 1px 0 rgba(0,0,0,.1); */
}

/* 
  This is the actual bar with stripes
  */
.bar-p span {
  display: inline-block;
  height: 100%;
  width: 100%;
  border: 1px solid var(--color-primary-blue);
  background-color: #CFC5F5;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  background-image:
    -webkit-linear-gradient(-45deg,
      var(--color-primary-blue) 25%,
      transparent 25%,
      transparent 50%,
      var(--color-primary-blue) 50%,
      var(--color-primary-blue) 75%,
      transparent 75%,
      transparent);
  background-image:
    -moz-linear-gradient(-45deg,
      var(--color-primary-blue) 25%,
      transparent 25%,
      transparent 50%,
      var(--color-primary-blue) 50%,
      var(--color-primary-blue) 75%,
      transparent 75%,
      transparent);
  background-image:
    -ms-linear-gradient(-45deg,
      var(--color-primary-blue) 25%,
      transparent 25%,
      transparent 50%,
      var(--color-primary-blue) 50%,
      var(--color-primary-blue) 75%,
      transparent 75%,
      transparent);
  background-image:
    linear-gradient(-45deg,
      var(--color-primary-blue) 25%,
      transparent 25%,
      transparent 50%,
      var(--color-primary-blue) 50%,
      var(--color-primary-blue) 75%,
      transparent 75%,
      transparent);
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  -ms-background-size: 50px 50px;
  background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
  -ms-animation: move 2s linear infinite;
  animation: move 2s linear infinite;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  -webkit-box-shadow: inset 0 10px 0 rgba(255, 255, 255, 0.2);
  -moz-box-shadow: inset 0 10px 0 rgba(255, 255, 255, .2);
  -ms-box-shadow: inset 0 10px 0 rgba(255, 255, 255, .2);
  box-shadow: inset 0 10px 0 rgba(255, 255, 255, .2);
}

/*
  Animate the stripes
  */
@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@-ms-keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}



.output-header {
  color: #111827;
  font-weight: 600;
  font-size: 15px;
  background-color: #f3f5fd;
  border-bottom: 1px solid #e3e2e6;
  border-top: 1px solid #e3e2e6;
}

.NewOverFlowScroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

/* Track */
.NewOverFlowScroll::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.NewOverFlowScroll::-webkit-scrollbar-thumb {
  background: #C5C4C8;
  border-radius: 10px;
}

.NewOverFlowScroll::-webkit-scrollbar-thumb:hover {
  background: #C5C4C8;
}

.custom-search-container {
  width: 280px;
  height: 44px;

  .text-secondary {
    color: #50D7ED !important;
  }

  .fa-search {
    font-weight: lighter;
  }

  input {
    background-color: unset;
  }

}


.custom-search-container-input {
  border: none;
  flex: 1;
  // ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  //     color: red;
  //     opacity: 1; /* Firefox */
  //   }
}

.custom-search-container-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A1A9B8;
}

.CancelButton {
  background-color: #f3f6fe !important;
  color: #50D7ED !important;
  border: 1px solid #f3f6fe !important;
}

.CancelButton:hover {
  background-color: #50D7ED !important;
  color: #fff !important;
  border: 1px solid #50D7ED
}

.CancelButton:active {
  box-shadow: none !important;
}

.confirmButton {
  background-color: #f3f6fe;
  color: #50D7ED;
  border: 1px solid #50D7ED;
}

.confirmButton:not(:disabled):hover {
  background-color: #50D7ED !important;
  color: #fff !important;
  border: 1px solid #50D7ED
}

.confirmButton:active {
  box-shadow: none !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  margin-top: 8px;
  transition: none;

  .accordion-arrow {
    margin-bottom: 0px;
  }
}

.border-right-2 {
  border-right: 1px solid #c2c8d1 !important;
}

.border-left-2 {
  border-left: 1px solid #c2c8d1 !important;
}

.MuiIconButton-root {
  padding: 3px !important;
}

.MuiAccordion-root:before {
  height: 0px !important;
}

#checkDisplay {
  .MuiPaper-root {
    border: none !important;
  }
}

.border-right {
  border-right: 1px solid #DBDEE4;
  ;
}

.border-left {
  border-left: 1px solid #DBDEE4;
  ;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: max-height 2s ease
}

.Icons {
  height: 18px !important;
  width: 18px !important;
}

.padding-unset {
  padding: unset !important;
}

.margin-unset {
  margin: unset !important;
}

.Label-2 {
  font-size: 14px;
  color: #111827;
}

.dropdown-menu {
  background-color: transparent;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  border: none;
}

.disable-click {
  pointer-events: none;
}

// .mb-7{
//   padding-bottom: 10rem;
// }
.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
}

.NoMinheight {
  min-height: auto !important;
}

.react-datepicker__day--selected {
  background-color: #50D7ED !important;
  color: #fff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: var(--color-secondary-purple);
  color: #fff;
}

.fw-600 {
  font-weight: 600 !important;
}

.opacity-1 {
  opacity: 1 !important;
  ;
}

.btn-primary {
  .fa-new {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(273deg) brightness(103%) contrast(103%) !important;
  }
}

.minWidthRerun {
  min-width: 70px;
}




.custom-radio-report {


  .custom-control-label {
    font-size: 16px;
  }

  .custom-control-input {
    width: 100px;
  }

  .form-check-label {
    font-size: 16px;
    margin-left: 5px;
  }
}


@media screen and (max-width: 1500px) {

  .reviewPageTab {
    .new-Input-Button {
      height: 34px !important;
    }
  }

  .h-44,
  .disabledNew,
  .custom-search-container {
    height: 34px;
  }

  #NewPageLayout {
    .page-item.active .page-link {
      font-size: 12px;
    }

    .page-item .page-link {
      font-size: 12px;
    }
  }

  .react-bootstrap-table {
    .NewTable {
      .customBG th {
        font-size: 11px;
      }

      tr td {
        font-size: 12px;
      }
    }
  }

  .Label,
  label {
    font-size: 12px !important;
  }

  .FileInputPadding {
    padding: 4px 15px !important;
  }

  .output-header {
    font-size: 13px;
  }
}

.rdrDateDisplayItemActive {
  border-color: #50D7ED !important;
}

.nav-tabs {
  border-bottom: 1px solid #DBDEE4;
  ;
}

.nav-tabs .nav-link.disabled {
  color: #B1B8BE;
  background-color: transparent;
  border-color: transparent;
}



.tableWrapperClass {
  min-height: 70vh;
}

@media screen and (min-width: 2560px) {
  .tableWrapperClass {
    min-height: 78vh;
  }
}


.auditTableWrapperClass {
  min-height: 70vh;
}

@media screen and (min-width: 2560px) {
  .auditTableWrapperClass {
    min-height: 72vh;
  }
}


.batchTableWrapperClass {
  min-height: 60vh;
}

@media screen and (min-width: 2560px) {
  .batchTableWrapperClass {
    min-height: 50vh;
  }
}


.bodyWrapperClass {
  border-radius: 30px;
}


// Scroll Fix

::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C5C4C8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C5C4C8;
}

.top0 {
  top: 0px !important;
}

.InfoHovDown {
  @extend .InfoHov;
}

.InfoHovDown:hover>.listInfoHov {
  left: 0px !important;
}

.InfoHovDown:hover>.listInfoHov::before {
  left: 95px;
}


.view-more-container {
  flex-shrink: 0;
  background-color: #F1EDFD;
  border-radius: 3px;
  padding: 3px 7px;
  cursor: pointer;
}




.scanHistoryTableWrapper {
  min-height: 87vh;
}

@media screen and (min-width: 2560px) {
  .scanHistoryTableWrapper {
    min-height: 90vh;
  }
}

.MoreCalenderReset {
  position: relative;
}

.MoreCalenderResetHov {
  display: none;
  z-index: -10;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.MoreCalenderReset:hover>.MoreCalenderResetHov {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  bottom: 50px;
  opacity: 1;
  border: 0.8px solid #333030;
  padding: 5px 10px;
}

.MoreCalenderReset .MoreCalenderResetHov::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 43%;
  top: 100%;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;
}

.MoreFilter {
  position: relative;
}

.MoreFilterHov {
  display: none;
  z-index: -10;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.MoreFilter:hover>.MoreFilterHov {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  bottom: 40px;
  opacity: 1;
  border: 0.8px solid #333030;
  padding: 5px 10px;
}

.MoreFilter .MoreFilterHov::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 45%;
  top: 100%;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;
}

.MoreCalenderResetHis {
  position: relative;
}

.MoreCalenderResetHovHis {
  display: none;
  z-index: -10;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.MoreCalenderResetHis:hover>.MoreCalenderResetHovHis {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  bottom: 58px;
  opacity: 1;
  border: 0.8px solid #333030;
  padding: 5px 10px;
}

.MoreCalenderResetHis .MoreCalenderResetHovHis::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 45%;
  top: 100%;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;
}

.MuiAccordionSummary-root {
  border-radius: 6px !important;
}

.MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.MuiAccordion-rounded:first-child {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.idui-popover {
  background-color: #333030 !important;
  color: #fff !important;
  font-size: 11px;
  font-family: "Open Sans";
  padding: 20px 10px !important;
  line-height: 1.6;
  font-weight: 400;
  border-radius: 15px !important;
  letter-spacing: 1px;
  z-index: 98;
}

.commonTableCss {

  .CommonDateCalendar {

    .rdrStartEdge,
    .rdrEndEdge {
      background: #80E5FF !important;
      background-color: #80E5FF !important;
      border: 1px solid #50D7ED !important;
    }

    .date-range-picker__toggle-button {
      padding: 10px 14px !important;
      border-radius: 6px;
    }

    .rdrStaticRange:hover .rdrStaticRangeLabel,
    .rdrStaticRange:focus .rdrStaticRangeLabel {
      background: rgba(120, 84, 247, 0.05);
    }

    .rdrMonthAndYearPickers select:hover {
      background-color: transparent;
    }

    .rdrInRange {
      background-color: #a0e4f5 !important;
    }

    .rdrDayNumber span:after {
      height: 0% !important;
    }

    .rdrDayNumber span {
      color: #1d2429;
      font-weight: 600
    }
  }
}

.rdrNextPrevButton {
  display: flex;
  align-items: center;
}

.rdrNextButton {
  margin-top: 2px;
}

.picky {
  width: 100%;
  position: relative;
}

.red-border {
  border: 1px solid red !important;
}

.date-range-picker .custom-filter-wrap {
  z-index: 98 !important;
  right: inherit !important;
}

.no-border {
  border: none !important;
  margin-bottom: 0px !important;
}

.rounded-corner {
  border: 1px solid #DBDEE4;
  border-radius: 8px;
  padding: 1.5px;
  margin-bottom: 1rem;
}

.rounded-corner-2 {
  border-right: 1px solid #DBDEE4;
  border-left: 1px solid #DBDEE4;
  border-bottom: 1px solid #DBDEE4;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1.5px;
  margin-bottom: 1rem;
}

.DateMSdiv {
  display: block;

  .time {
    color: #687182;
    margin-top: 5px;
  }
}

.back-btn-svg {
  height: 25px;
  width: 25px;
  // background-color: #fff;
  background-color: #DBD7FB;
  border-radius: 50%;
}

.react-datepicker-popper {
  z-index: 1010 !important
}

.react-datepicker-wrapper {
  width: 100%;
}

.not-allowed {
  cursor: not-allowed !important;
}

.disable-content {
  cursor: not-allowed !important;
  color: grey !important;
  opacity: 0.7 !important;

  &>input {
    cursor: not-allowed !important;
  }

  &>label {
    cursor: not-allowed;
  }
}

.disable-icons {
  cursor: not-allowed !important;
  background-color: grey !important;
  opacity: .6 !important;
  color: #fff !important;

  .card-type__detail {
    color: #fff !important;
  }
}

.table-Seatch {
  position: relative;

  a {
    position: absolute;
    left: 5px;
    top: 6px
  }

  input {
    padding-left: 25px;
    height: 38px;
  }

  @media only screen and (max-width: 1280px) {
    a {
      top: 5px;
    }

    input {
      padding-left: 25px;
      height: 34px;
    }
  }
}

.buttoncssHandle {
  .newTabList {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 1280px) {

  .picky__input {
    height: 34px !important;
  }
}

#scanFileUpload {
  .newTabList {
    margin-top: 0px !important;
  }
}



.picky__input:focus {
  border-color: #50D7ED !important;
  color: #50D7ED !important;
}

.picky__input span {
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 90px !important;
  text-overflow: ellipsis !important;
}

.picky__dropdown .option {
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  border-bottom: none;
}

.picky__dropdown {
  margin-top: 0px !important;
}

.picky__dropdown .option input {
  // top: 5px;
  margin-right: 8px;
}

// .font-15{
//   font-size: 15px!important;
// }
.picky__input:after {
  width: 5px !important;
  height: 5px !important;
  border-left: 1px solid #595959 !important;
  border-bottom: 1px solid #595959 !important;
  transform: rotate(-45deg);
  position: absolute;
  margin-left: 2px;
  vertical-align: middle;
  font-family: FontAwesome !important;
  content: "" !important;
  border-top: none;
  border-right: none;
  // border-left: none;
  right: 8px !important;
  top: 16px;
}

.picky__input {
  overflow: hidden;
  width: 100%;
  height: 44px;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #111827;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
}

.searchTableFilter {
  input {
    height: 38px !important;
  }
}

.MoreCalenderResetButton {
  .resetButton {
    height: 38px !important;
  }
}


#sideBarView {
  .modal-backdrop.show {
    opacity: 1 !important;
  }

  .modal-backdrop {
    background-color: unset;
    backdrop-filter: blur(10px);
  }
}

.buttoncssHandle {
  .btn-primary {
    height: 38px !important;

    @media screen and (max-width: 1500px) {
      height: 34px !important;
    }
  }
}

.searchFade::placeholder {
  color: rgba(0, 0, 0, 0.3);
  /* Semi-transparent color */
  transition: color 0.3s ease;
}