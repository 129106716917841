.site-navigation-panel {
  //--site-sidebar-width var is in layouts file
  // box-shadow: 4px 0 8px 2px rgba(0, 0, 0, 0.1);
  background: var(--color-primary-blue);

  &:hover,
  &.is-locked {
    .brand-logo {
      &-container {
        // background: var(--color-primary-blue);
        // border-bottom-right-radius: 44px;
      }
    }

    .nav-link__title {
      max-width: 555rem;
      opacity: 1;
      transition: max-width 10ms linear 250ms, opacity 250ms ease-in-out 250ms;
    }

    .nav-link__projectName,
    .nav-link__comp-name {
      max-width: 555rem;
      opacity: 1;
      transition: max-width 10ms linear 250ms, opacity 250ms ease-in-out 250ms;
    }

    .logo-white-clor {
      color: #fff !important;
    }

    .site-navigation-panel-lock__container {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover:not(.is-locked) {
    .brand-logo-img {
      //   transform: rotateZ(180deg);
      //   transition: transform 400ms ease-in-out;
    }
  }

  .brand-logo {
    &-container {
      width: var(--site-navigation-panel-width);
      height: var(--app-header-height);
      padding: 1rem;
      text-align: center;
      //   background: rgba(255, 255, 255, 0.04);
      //   transition: border-radius 250ms ease-in-out, background 250ms ease-in-out;
    }

    &-img {
      max-width: 64px;
      //   padding: 0.5rem;
      //   border-radius: 555px;
      //   background: #fff2;

      //   transition: transform 0ms;
      @include filter-white;
    }
  }

  .site-navigation-panel-lock {
    &__container {
      position: absolute;
      right: 1.5rem;
      top: 1.55rem;

      pointer-events: none;

      cursor: pointer;
      opacity: 0;
      transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    }

    &__toggle-input,
    &__icon-unlock.hidden,
    &__icon-lock.hidden {
      display: none;
    }

    &__icon-unlock,
    &__icon-lock {
      pointer-events: none;
    }

    &__icon-lock {
      color: var(--color-theme-primary);
      text-shadow: 0 0 4px 1px var(--color-theme-primary);
    }

    &__toggle-input-label {
      --size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--size);
      width: var(--size);

      pointer-events: none;
      opacity: 0;

      cursor: pointer;
      border-radius: 555rem;
      background: rgba(255, 255, 255, 0.04);
      transition: background 180ms ease-in-out;

      &:hover {
        background: rgba(255, 255, 255, 0.07);
      }
    }
  }

  &__nav {
    // height: calc(100vh - 5rem);
    width: 100%;
    // padding: 1rem 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .nav-link {
    // --padding-horizontal: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    // padding: 0.5rem 1rem;
    color: #fff;
    opacity: 1;

    &__icon-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(var(--site-navigation-panel-width) - (var(--padding-horizontal) * 2));

      &--profile {
        --size: 3rem;
        width: var(--size);
        height: var(--size);
        min-width: var(--size);
        border-radius: 555rem;
        overflow: hidden;
        background: #fff;
        margin-right: 0.75rem;
      }
    }

    &__icon {
      &--mdi {
        color: #fff;
        font-size: 19px;
        opacity: 0.8;
      }
    }

    .svg-icon {
      // --size: 2.7rem;
      // height: calc(0.95 * var(--size));
      // padding: 0.75rem;
      border-radius: 4px;
      transition: background 250ms ease-in-out;
    }

    &__title {
      display: inline-block;
      max-width: 0;
      opacity: 0;
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 400;
      vertical-align: text-bottom;
      color: #fffa !important;

      // text-transform: uppercase;
      transition: max-width 10ms linear, opacity 100ms ease-in-out;

      &--brand-name {
        // font-size: 13px;
        position: absolute;
        right: 0.7rem;
        top: 1.88rem;
        font-weight: 700 !important;
      }
    }


    &__projectName,
    &__comp-name {
      display: inline-block;
      max-width: 0;
      opacity: 0;
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: 600;
      vertical-align: text-bottom;
      pointer-events: none;


      // text-transform: uppercase;
      transition: max-width 10ms linear, opacity 100ms ease-in-out;
    }

    &__comp-name {
      color: #fffa !important;
    }

    &__projectName {
      color: #111827 !important;
    }

    &--profile {
      .svg-icon {
        background: none !important;
      }
      // .NavProjectName{
      //   display: flex!important;
      //   transition: display 100s ease-in-out!important;
      // }
      .nav-link__icon {
        // --size: 75%;
        --size: 101%;
        width: var(--size);
        height: var(--size);
        object-fit: contain;
      }
    }

    &:hover,
    &:focus,
    &.active {
      opacity: 1;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      outline: none !important;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 5px;
      background: var(--color-theme-primary);
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &.active {
      background: rgba(120, 84, 247, 0.05);

      .nav-link__title {
        color: #50D7ED;
      }

      .svg-icon {
        // background: rgba(120, 84, 247, 0.05);
        background: unset;
      }
    }

    &.active:before {
      transform: scaleX(1);
    }
  }
}

:focus {
  outline: none !important;
}

.site-navigation-panel .brand-logo-img {
  max-width: 121px;
  filter: none !important;
}