.input-button {
  height: var(--input-height);
  padding: 0.5rem 1.5rem;

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  background: var(--color-primary-blue) !important;
  color: #fff !important;
  border: none;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  transition: box-shadow 200ms ease-in-out, filter 250ms ease-in-out,
    background 250ms ease-in-out, opacity 250ms ease-in-out;

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  }

  &.disabled {
    background: #666 !important;
    border-color: #444 !important;
  }

  &.fade {
    opacity: 0.3;
  }
}

.new-Input-Button{
  height: var(--input-height);
  padding: 10px 24px;

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  height: 44px;
  background: transparent !important;
  color: #50D7ED!important;
  border: none;
  border: 1px solid #50D7ED;
  border-radius: 6px;
  transition: box-shadow 200ms ease-in-out, filter 250ms ease-in-out,
    background 250ms ease-in-out, opacity 250ms ease-in-out;

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
  }

  &:hover,


  &.disabled {
    background: #666 !important;
    border-color: #444 !important;
  }

  &.fade {
    opacity: 0.3;
  }

  @media screen and (max-width: 1500px) {
    height: 38px;
    font-size: 12px;
  }
}
.new-Input-Button:hover{
  background: #50D7ED !important;
  border-color: #50D7ED !important;
  color: #fff!important;
  .fa-new{
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(273deg) brightness(103%) contrast(103%)
  }
}