.notFoundHeading{
    font-size: 13.5rem;
    color: var(--color-primary-blue);
    font-weight: bolder;
}
.notFoundSubheading{
    font-size: 20px;
    span{
        font-weight: 700;
        color: var(--color-primary-blue);
    }
}
.mt-6{
    margin-top: 6rem;
}
#notFound{
.backButton:hover{
    font-size: 16px!important;
        font-weight: 500!important;
    }
    .submit-button{
        padding:0.8rem 1.5rem;
        font-size: 16px;
    }
  
}