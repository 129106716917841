.sign-in-page {
  padding: 0 !important;
  background: var(--color-primary-blue);
  overflow-y: hidden;

  .page-info__container {
    background: linear-gradient(45deg, #50D7ED -20.46%, #B166C9 84.3%);
  }

  .page-info__container,
  .login-form-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 1rem;
  }

  .r-text-color {
    color: rgba(17, 24, 39, 0.60);
  }

  .login-label {
    color: #111827;
  }

  .page-info {
    &__container {
      position: relative;
    }

    &__logo {
      position: absolute;
      left: 1rem;
      top: 1rem;
      width: 13rem;
    }

    &__heading,
    &__description {
      max-width: 55ch;
    }

    &__heading {
      margin-bottom: 1.5rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #f6fafb;
      text-transform: uppercase;
    }

    &__description {
      font-weight: 500;
      letter-spacing: 0.08px;
      color: #ffffff;
    }
  }

  .login-form-card {
    background: #F4F7FC;
    border-radius: 10px 0 0 10px;

    .login-form-card__content-container {
      // width: 285px;
      width: 50%;
    }

    .FortrexLogo {
      width: 200px;
      height: auto;
      position: absolute;
      right: 1rem;
      top: 0.9rem;
    }

    .home-menu {
      display: inline-block;
      width: 64px;
      position: absolute;
      right: 1rem;
      top: 0.9rem;
      font-weight: 600;
      color: var(--color-primary-blue);
      cursor: pointer;
    }

    &__heading {
      margin-bottom: 3rem;
      font-size: 38px;
      font-weight: 800;
      letter-spacing: 0.1px;
      color: #6C4CDE;
      text-transform: uppercase;
      opacity: 0.22;
    }
  }

  .login-form {
    margin-bottom: 3rem;

    opacity: 0;
    transform: translateY(8px);
    animation: fade-in-rise-up 400ms ease-out forwards;

    &__field-container {
      margin-bottom: 1.25rem;

      .input-text-field {
        width: 100%;
      }
    }
  }

  .link {
    font-weight: 500 !important;
    color: #0a0864 !important;
    font-size: 17px;
  }
}

.sign-in-forgot-password-modal {
  // top: 50%;
  // transform: translateY(-50%);
}

.forgot-password-modal input {
  width: 100%;
}

.aHover:hover {
  font-weight: 600;
}

@media screen and (max-width: 1500px) {
  .sign-in-page {
    .login-form-card {
      .login-form-card__content-container {
        width: 60%;
      }
    }
  }
}