.StartNewScan {
    border-top: 1px solid #e8e8e8;
        height: 420px;
        width: 670px;
    
    .heading{
        font-size: 16px;
        color:#717273;
        font-weight: 400;
    }

    .upload-type {
        gap: 15px;
    }

    .card-type {
        border: 1px solid #d0d0d0;
        border-radius: 6px;
        color: #131415;
        cursor: pointer;
        height: 275px;
        padding: 25px;
        transition: box-shadow .3s ease-in-out;
        font-weight: unset;
        text-decoration: none;

        img {
            vertical-align: middle;
            width: auto;
            height: 5.5rem;
        }
    }

    .active {
        background: linear-gradient(90deg, #50D7ED 11.54%,  #B166C9 84.3%);
        border-color: var(--color-primary-blue);
        color: #fff !important;

        .card-type__detail {
            color: #fff;
        }
    }

    .fw-600 {
        font-weight: 600;
    }

    .card-type__detail {
        color: #717273;
    }

    a.btn-primary {
        color: #fff;
        font-size: 14px;
    }

    .single {
        width: 350px
    }

    @media screen and (max-width: 1500px) {
        height: 440px;
        width: 550px;
        .card-type {
            height: 300px;
        }

        a.btn-primary {
            font-size: 12px;
        }
    }

}