.ReactTags__tag {
    background-color: #f8f6ff;
    padding: 5px 10px;
    border-radius: 6px;
    margin: 10px !important;
    font-size: 14px;
    color: #111827;
}

.tags-input-wrapper {
    .rti--container {
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        color: #111827 !important;
        min-height: 44px;
        max-height: 100px;
        overflow-y: auto;
        width: 100%;
        font-family: "Open Sans";
        border-radius: 6px;
        border: 1px solid #11182733;
        background-color: #fff;
        outline: 0;
        display: block;
        padding-left: 20px;
        padding-right: 20px;

        .rti--tag {
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 5px 15px;
            background-color: #f8f6ff !important;
            color: #111827 !important;
            font-size: 14px;
            font-weight: 500;

            button {
                color: #805ef8;
                font-size: 12px;
            }
        }

        .rti--input {
            font-size: 14px;
        }
    }

    .rti--container:focus-within {
        border: 1px solid #11182733;
        box-shadow: none;
    }
}


@media screen and (max-width: 1500px) {
    .tags-input-wrapper {
        .rti--container {
            min-height: 38px;
            padding-left: 15px;
            padding-right: 15px;
            .rti--input {
                font-size: 12px;
            }
            .rti--tag {
                font-size: 12px;
            }
        }
    }
}