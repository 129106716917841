.full-page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  opacity: 0;
  transform: translateY(8px);
  animation: fade-in-rise-up 400ms ease-out forwards;
}


.splash-screen {
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  transform: translateY(8px);
  animation: fade-in-rise-up 400ms ease-out forwards;
}
.MainLoaderCenterAlign
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
