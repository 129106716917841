.profile-edit-page {
  .profile-edit-form-card {
    // max-width: 1200px;
    // margin: 0 auto;
    // padding: 1rem;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
    // border: 1px solid #e2e4e5;
    // border-radius: 10px;
  }

  .NewInput {
    background: #FFFFFF;
    border: 1px solid #cfd1d4;
    border-radius: 6px;
  }

  .input-field {
    margin-top: 1.5rem;

    &__label {
      display: block;
      padding-left: 0.25rem;
      font-size: 14px;
      color: var(--color-primary-blue-light);
    }

    .input-text-field {
      width: 100%;
    }
  }

  .submit-button {
    max-width: 200px !important;
  }

  .admin-basic-card-container {
    background-color: #FFFFFF;
    padding: 20px;
    // border-radius: 4px;
    border: 1px solid #e8e8ea;
    border-radius: 8px;
  }

  .card {
    border: 1px solid #c2c8d1;
    border-radius: 4px;
    padding: 24px 16px;
    width: 100%;
    // height: 147px; // when button is applied
    height: 100px;
  }

  .card-hover-shadow {
    transition: 0.3s;
  }

  .card-hover-shadow:hover {
    box-shadow: 0 6px 12px rgba(140, 152, 164, 0.25) !important;
  }

  .card-body {
    padding: 0;
  }

  .icon-name-container {
    // border-bottom: 1px solid #e8e8ea;


    .name-container {
      max-width: 180px;
    }

    .text-color-2 {
      color: #a0a3a9
    }

    .text-color {
      color: #111827;
    }

    .text-wrap-new {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .p-logo-container {
    height: 50px;
    width: 50px;
    padding: 5px;
    border: 1px solid #cfd1d4;

    img {
      max-height: 40px;
      width: 40px;
    }
  }

  .new_heading1 {
    font-size: 20px;
    font-weight: 500;
    color: #111827;
  }

  input:disabled {
    background: #F6F6F6;
  }

  .buttonCard2 {
    background-color: #FDEFEF;
  }

  .buttonCard1,
  .buttonCard2 {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 29px;
    font-size: 12px;
    font-weight: 500;
    padding: 6px, 12px, 6px, 12px;
    border-radius: 4px;

    .p1 {
      color: #50D7ED;
    }

    .p2 {
      color: #E55656;
    }
  }

  .admin-basic-info-container-profile {
    height: fit-content;
    background-color: #FFFFFF;
    padding: 30px 20px;
    border: 1px solid #e8e8ea;
    border-radius: 8px;

    .admin {
      &__value-label {
        // font-size: 24px;
        font-weight: 600;
        color: #131415;
        width: 200px;

        &--small {
          // font-size: 16px;
          font-weight: 500;
          color: #70747D;
        }
      }
    }
  }

  .profile-completion-percentage-container {
    max-width: 350px;
    display: flex;
    align-items: center;

    &__bar {
      flex: 1;
    }
  }
}

.profileCountrySelect{
  .picky__filter__input {
    font-size: 14px;
    font-family: "Open Sans"!important;
}
 .picky__input{
  border-left: none;
  border-radius: 0 6px 6px 0;
  border-color: #d0d5d8;
  height: 35px;
}
.picky__dropdown .option:focus , .picky__dropdown .option:hover
{
  outline: none!important;
  color: #50D7ED !important;
  background: rgba(120,84,247,.05) !important;
  border: none;
}

.identifierOptions .picky__dropdown .option input
{
display: inline;
}

.picky__dropdown .option
{
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  border-bottom: none;
}
.picky__dropdown{
  border-bottom: 1px solid #eee !important;
  border-left: 1px solid #eee !important;
  border-right: 1px solid #eee !important;
}

  .picky__input{
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #111827 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0 20px !important;
    height: 44px;
    border-radius: 6px !important;
    border: 1px solid #cfd1d4 !important;
  }
  .picky__input::after{
    top: 9px !important;
  }
  .picky__input--disabled{
    background: #F6F6F6 !important;
    border-radius: 6px !important;
    border: 1px solid #cfd1d4 !important;
  }

  @media screen and (max-width: 1500px){
    .picky__input,
  .picky__input--disabled {
    padding: 0 15px !important;
    height: 38px;
    font-size: 12px !important;
    color: #111827 !important;
  }
  }

}

#profileProject {
  #single-select-dp{
    width: 42px !important;
    height: 30px;
    font-size: 12px;
    padding: 5px 4px 5px 6px;
  }
  .add-user-div-dropdown-content{
height: 25px;
padding-left: 10px !important;
padding-right: 10px !important;
  }
.dropdown-div-css{
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.02) !important;
  }
  #single-select-dp .status-div-dropdown{
    position: absolute;
    left: 0;
    top: -111px;
    width: 50px !important;
    // width: 300px;
    background-color: white;
    border: 1px solid rgba(17, 24, 39, 0.2);
    border-radius: 8px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
  }
  .profile-project-card-container{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(260px,1fr));
    grid-gap: 20px;
    gap: 20px;
  }
  .selectValue{
      display: flex;
      flex-flow: column wrap;
      background-image: url("./../../assets/images/tablesign.svg");
      background-position:right 10px center;
      background-size: 7px;
      background-repeat: no-repeat;
      border: 1px solid #ced4da !important;
      font-size: 12px !important;
      color: #495057 !important;
      padding: 4px 16px 4px 4px !important;
      appearance: none;
      border-radius: 0.25rem;
      vertical-align: middle;
      font-weight: 600!important;
  }

  .page-item.active .page-link {
    background: rgba(120,84,247,.08);
    border: 1px solid #50D7ED;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #50D7ED!important;
  }
  
  .page-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(120,84,247,.8);
    background-color: initial;
    border: none;
    margin: 0 2px;
  }
}

#profileProject{
  .profileSelect{
    .paddingbutton {
      padding: 12px 5px;
  }
  }
}

#profileScanCount {

   .scan-count-container {
    border : 1px solid rgba(120, 84, 247, 0.2);
    background-color: rgba(248, 248, 248, 1);
    border-radius: 4px;
    position: relative;

    .apexcharts-datalabels-group { 
      display: none;

    }

    .chart-inner-text {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      font-weight: 600;
      color: #111827;
      text-align: center;
      pointer-events: none;
      margin-bottom: 2px;

       & > div {
        width:90px;
        height: 90px;
        border-radius: 100%;
        background-color: rgba(120, 84, 247, 0.1);
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         & > h1 {
          color:#714DF2;
          font-weight: 700;
         }

         & > h4 {
          color: #3E4451;
         }
       }
    }
    
   }


   .scan-count-chart-container {
    width:130px;
    height: 130px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

     & > .apexcharts-canvas > .apexcharts-toolbar {
      display: none !important;
     }
   }
   
}



#crrProfileCircle {

  // Crr Profile Css
  .circle-container {
    width: 75px;
    height: 75px;
    // background: #E1E1E1;
    background: #E8DFDF52;
    border-radius: 50%;
  }

  .circle-container .circle .item,
  .circle-container .circle .fill {
    width: 75px;
    height: 75px;
    position: absolute;
    border-radius: 50%;
  }

  .circle-container .circle .item {
    clip: rect(0px, 76px, 76px, 37px);
  }

  .circle-container .circle .item .fill {
    clip: rect(0px, 37.9px, 75px, 0px);
    background-color: var(--TrackColor);
  }

  .circle-container .circle .item.layer-1,
  .circle-container .circle .fill {
    transform: rotate(calc((180deg / 100) * var(--progress-value)));
    /* Use the dynamic value here */
    transition: transform 0.5s ease-in-out;
  }

  .inside-content {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--Bgcolor);
    text-align: center;
    margin-top: 7.5px;
    margin-left: 7.5px;
    position: absolute;
    // font-weight: 700;
    font-family: "Poppins", sans-serif;
    // font-size: 14px;
    // line-height: 130px;
    box-shadow: 0px 0px 8px 0px #00000040;
  }

}
@media screen and (max-width: 1500px){
  .admin-basic-info-container-profile {
    padding: 20px 10px !important;
  }
}