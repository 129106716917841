.ticker-card {
  --card-bg: #fff;
  --text-color: #111827;
  --icon-color: #fff;
  --icon-bg-color: #a395f9;
  border: 1px solid #DBDEE4;
    border-radius: 8px;
    padding: 15px 15px 15px 24px;

  justify-content: space-between;
  background: var(--card-bg);
  transition: background 250ms ease-in-out;
  // width: 307px;
  // height: 95px;
  max-width: 100%;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    margin-right: 0.5rem;
    font-weight: 400;
    opacity: 0.8;
    color: rgba(17,24,39,.8);
    font-size: 14px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 1.4rem;
    transform: scale(1.18);

    .font-icon {
      // background: var(--card-bg);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: var(--icon-color);
      padding: 0.25rem;
      border-radius: 555rem;
      background: var(--icon-bg-color);
      --size: 1.35rem;
      width: var(--size);
      height: var(--size);
      min-width: var(--size);
      min-height: var(--size);
    }
  }

  &__icon:not(.ticker-card__icon--font-icon) {
    @include filter-secondary-purple;
    position: relative;
    top: -3px;
  }

  &__value {
    font-weight: 600;
    font-size: 16px;
    color: #111827;
    letter-spacing: 1.1px;
    color: var(--text-color);
  }

  &__title,
  &__value {
    text-align: left;
  }

  &--active {
    --card-bg: var(--color-secondary-purple);
    --text-color: #fff;
    --icon-color: var(--color-secondary-purple);

    .ticker-card__icon--active:not(.ticker-card__icon--font-icon) {
      @include filter-white;
    }

    .font-icon {
      background: #fff;
    }
  }
}

.event-details-popup {
  .ticker-card{
    &__title {
     margin-right: 0 !important;
    }

    &__value {
    font-size: 20px !important;
    }
  }
}
