#workspace-filter-select {

  // For active filter selection

.activeSelected{
  .picky__input {
    border-color: #50D7ED;
    color: #50D7ED;

    .picky__placeholder {
      color: #50D7ED;
    }
  }
  .picky__input::after{
    color: #50D7ED !important;
    border-left: 1px solid #50D7ED !important;
    border-bottom: 1px solid #50D7ED !important;
  }
} 

.CommonDateCalendar1 {
.btn-primary {
  border-color: #50D7ED !important;
}
.btn-primary {
  padding: 10px 14px !important;
}

.date-range-picker__toggle-button{
  height: 38px !important;
  max-height: 38px !important;
  border-radius: 6px !important;
}

 .date-range-picker__toggle-button__text{
color: #50D7ED !important;
}
}

  .picky__input {
    position: relative;
    border-color: #d0d5d8;
    overflow: hidden;
    width: 100%;
    height: 44px;
    padding: 0 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #111827;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100px;
      text-overflow: ellipsis;
    }

  }

  .picky__input:focus {
    border-color: #50D7ED;
    color: #50D7ED;

    .picky__placeholder {
      color: #50D7ED;
    }

  }
.right0{
  .rdrDateRangePickerWrapper {
    right: 0px!important;
  
  }
}
  .CommonDateCalendar {

    .rdrDayInPreview,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      border: none;
    }

    .date-range-picker__toggle-button {
      height: 44px !important;
      max-height: 44px !important;
      border-radius: 6px !important;
    }

    .btn-primary {
      padding: 10px 14px !important;
    }

    .date-range-picker__toggle-button__text {
      font-size: 14px !important;
      margin-left: 7px !important;
      color: #595959;
    }

    @media screen and (max-width: 1500px){
      .date-range-picker__toggle-button__text {
      font-size: 12px!important;
  }
    }

    .custom-filter-wrap {
      position: inherit;
    }

    .rdrDateRangePickerWrapper {
      position: absolute;
      z-index: 98 !important;
      right: inherit;
      width: 450px;
      max-width: 100vw;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    }

    .rdrDayHovered {
      border: none;

      .rdrDayEndPreview {
        border: none;
        border-radius: 0px;
      }

      .rdrDayStartPreview {
        border: none;
        border-radius: 0px;
      }
    }

    .rdrInRange {
      background-color: #80E5FF !important;
    }

    .rdrSelected,
    .rdrStartEdge,
    .rdrEndEdge {
      background: #50D7ED !important;
      background-color: #50D7ED !important;
      border: 1px solid #50D7ED!important;
    }
    .rdrStartEdge{
      border-top-left-radius: 6px!important;
      border-bottom-left-radius: 6px!important;
    }
    .rdrEndEdge{
      border-top-right-radius:  6px!important;
        border-bottom-right-radius: 6px!important;
    }
    .rdrDayNumber {
      color: #111827;
      font-weight: 600;

      span {
        width: 100%;
      }

      ;

      span:after {
        height: 0% !important;
      }
    }
  }

  .picky__dropdown .option:hover,
  .picky__dropdown .option.selected {
    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }

  .picky__filter__input {
    font-size: 14px;
    font-family: "Open Sans" !important;

  }

  // .picky__dropdown .option input
  // {
  // display: none;
  // }
  .picky__placeholder {
    color: #595959;
    font-size: 14px !important;
    max-width: 20ch;
    text-overflow: ellipsis;
  }

  .picky__dropdown .option:focus,
  .picky__dropdown li:focus {
    outline: none !important;
  }

  .picky__dropdown .option.selected {
    color: #50D7ED;
    background: var(--color-primary-blue-light);
    border: none;
  }

  .forcedDisabled .picky__input {
    background-color: #e9ecef;
    opacity: 1;
  }

  .picky__dropdown .option input {
    margin-right: 8px;
    min-width: 17px!important;
  }
}

.picky__input:focus::after {
  color: #50D7ED;
  border-left: 1px solid #50D7ED;
  border-bottom: 1px solid #50D7ED;
}

.picky__input::after {
  width: 5px !important;
  height: 5px !important;
  border-left: 1px solid #595959 !important;
  border-bottom: 1px solid #595959 !important;
  transform: rotate(-45deg);
  position: absolute;
  margin-left: 2px;
  vertical-align: middle;
  font-family: FontAwesome!important;
  content: ""!important;
  border-top: none;
  border-right: none;
  // border-left: none;
  right: 8px !important;
  top: 16px;
}

.pr-15px {
  padding-right: 15px;
}

// .monthlyOptions .picky__dropdown .option input
// {
// display: inline;
// border-radius: 2rem;
// }
.picky__dropdown .option {
  padding: 0.5rem 0.5rem;
  font-size: 14px;
  border-bottom: none;
}

.picky__dropdown .option.selected {
  background-color: none;
  border-bottom: none;
}

.TabCount {
  color: #50D7ED;
  background-color: #D7D5FF;
  border-radius: 13px;
}

.filterButton {
  border-color: #50D7ED;
  overflow: hidden;
  height: 44px;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #50D7ED;
  border: 1px solid#50D7ED;
  border-radius: 6px;
}

.FilterDate {

  input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
  }

  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }
}

.CalendarCustomInput {
  padding: 11px 14px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #cfd1d4;

  span {
    color: #595959;
    font-size: 14px !important;

  }
}

.temppad {
  padding: 10px 14px !important;
}

.react-datepicker__header--custom {
  background-color: #fff!important;

  .arrowButtons {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #eff2f7;
  }

  .dropdown {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    cursor: pointer;
    text-align: center;
  }
}

@media screen and (max-width: 1500px) {
  #workspace-filter-select {
    .picky__input {
      height: 38px;
      font-size: 12px;
    }

    .picky__placeholder {
      font-size: 12px !important;
    }

    .picky__filter__input {
      font-size: 12px;
    }

    .CommonDateCalendar {
      .date-range-picker__toggle-button {
        height: 38px !important;
        max-height: 38px !important;
      }
    }
  }

  .picky__dropdown .option {
    font-size: 12px;
    padding: 6px 14px;
  }

  .filterButton {
    height: 38px;
  }
}