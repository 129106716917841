.chat-container {
  display: flex;
  flex-direction: column;
  // height: 83%;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;

  .table td {
    border: 1px solid #dee2e6 !important;
  }

  .table th {
    padding: 5px 20px 5px 5px !important;
    border: 1px solid #dee2e6 !important;
  }
}

.selectedText {
  color: black;
}

.unselectedMessage {
  color: #d7cece;
}

.unselectedMessageT {
  background-color: #f0eded;
}

.dTuydp {
  display: none !important;
}

.doc-viewer-container {
  width: 100%;
  height: 80vh;
  /* Default height for normal view */
  position: relative;
}

.doc-viewer-container.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  /* Ensure the viewer is above other content */
  background-color: white;
}

.doc-viewer-container button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
  /* Ensure the button is above the viewer */
}

.view-container {
  height: 100%;
  border: 1px solid #ccc;
  padding: 10px 20px 20px 20px;
  border-radius: 5px;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  // overflow-y: hidden;
  padding-right: 2px;
}

// .messages-container:hover {
//   overflow-y: auto;
// }

.sized-container-div {
  iframe {
    html {
      body {
        .adobe-dc-view {
          .zA6MfG_spectrum--medium {
            .react-spectrum-provider {
              .MainApp__app___d1GrX {
                .MainApp__middle___UZo47 {
                  .FileView__main___cET3T {
                    .FileView__middle___xVfPx {
                      .sdk-Preview-preview {
                        .App__App___3o5IP {
                          .PreviewWrapper__PreviewContainer___1AbcA {
                            .Preview__Preview___3NGGX {
                              .DocumentView__DocumentView___1NM7b {
                                .SlideShowView__SlideShowView___jQb_1 {
                                  .SinglePageView__SinglePageView___fdDFC {
                                    .PageView__PageView___33Vmc {
                                      margin: 0 auto !important;
                                      width: 100vw;

                                      .ImgBitmap__image___29vcf {
                                        width: 80vw !important;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .PageView__PageView___33Vmc {
//   width: 100vw !important;
// }

// .ImgBitmap__image___29vcf {
//   width: 100vw !important;
// }

.search-suggestion-c {
  max-height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 10px;
  overflow-y: scroll;
  border: 1px solid #ccc;
}

.hg-y {
  background-color: #9fe9fb;
}

.message {
  margin-bottom: 10px;
  padding: 4px 8px;
  border-radius: 8px;
  max-width: 90%;
  width: fit-content;
  word-wrap: break-word;
}

.custom-list {
  padding: 0;
  /* Remove default padding */
  margin: 0;
  /* Remove default margin */
  list-style-type: none;
  /* Remove default list styling */
}

.custom-list li {
  position: relative;
  /* Position relative to use for absolute positioning of the marker */
  padding-left: 2em;
  /* Space for the custom marker */
  margin-bottom: 0.5em;
  /* Space between items */
}

.custom-list li::before {
  content: '•';
  /* Custom marker (bullet) */
  position: absolute;
  /* Absolute positioning for the marker */
  left: 0;
  /* Position the marker at the left edge */
  color: black;
  /* Color of the marker */
  font-size: 1.5em;
  /* Size of the marker */
  line-height: 1;
  /* Adjust line height if needed */
}

// ul {
//   padding-left: 1.5em; /* Adjust this value based on your needs */
//   list-style-position: outside; /* Ensure the marker is outside the content area */
// }

// /* Optional: If you need to adjust the padding specifically for list items */
// li {
//   padding-left: 0.5em; /* Adjust this value to ensure no overlap */
// }

.message1 {
  margin-bottom: 10px;
  padding: 4px 8px;
  border-radius: 20px;
  max-width: 90%;
  width: fit-content;
  word-wrap: break-word;

  a {
    color: #33D6FF !important;
  }
}

.message2 {
  padding: 0px 4px;
  border-radius: 20px;
  max-width: 90%;
  width: fit-content;
  word-wrap: break-word;

  a {
    color: #33D6FF !important;
  }
}

// a, .message > div > a {
//   text-decoration: underline;
// }

.message.user {
  background-color: #b5eefd;
  color: black;

}

.message.system {
  background-color: #f0f0f0;
  color: #333;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
}

.attachFileChat {
  position: absolute;
}


.docViewerPage {

  #header-bar {
    min-height: 0 !important;
  }

  button:disabled,
  button[disabled] {
    // border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  .commentSaveCss {
    filter: brightness(0) saturate(100%) invert(84%) sepia(54%) saturate(2123%) hue-rotate(155deg) brightness(95%) contrast(95%);
  }

  .MuiTab-root {
    text-transform: capitalize;
  }

  .heightMan {
    height: 86vh;
  }

  .contCss {
    height: 83%;
  }

  // .contCss100 {
  //   height: 100%;
  // }

  .contCss100 {
    height: 105%;
  }

  @media (min-width: 1279px) and (max-width: 1421px) {
    .heightMan {
      height: 87vh;
    }

    // .contCss {
    //   height: 79.5%;
    // }

    .contCss {
      height: 85.5%;
    }
  }

  @media (min-width: 1422px) and (max-width: 1599px) {
    // .contCss {
    //   height: 81.5%;
    // }

    .contCss {
      height: 87.5%;
    }

    .contCss100 {
      height: 105%;
    }
  }

  @media (min-width: 1600px) and (max-width: 1768px) {
    .heightMan {
      height: 85.5vh;
    }

    // .contCss {
    //   height: 84%;
    // }

    .contCss {
      height: 89%;
    }

    .contCss100 {
      height: 103.5%;
    }
  }


  @media (min-width: 1769px) and (max-width: 1920px) {
    .heightMan {
      height: 85vh;
    }

    // .contCss {
    //   height: 85.5%;
    // }

    .contCss {
      height: 90%;
    }

    .contCss100 {
      height: 103%;
    }
  }


  @media (min-width: 1921px) and (max-width: 2560px) {
    .heightMan {
      height: 84vh;
    }

    // .contCss {
    //   height: 89%;
    // }

    .contCss {
      height: 92%;
    }

    .contCss100 {
      height: 102%;
    }
  }

  @media (min-width: 2561px) and (max-width: 3841px) {
    .heightMan {
      height: 83vh;
    }

    // .contCss {
    //   height: 92%;
    // }

    .contCss {
      height: 94.5%;
    }

    .contCss100 {
      height: 101%;
    }
  }

  @media (min-width: 3842px) and (max-width: 5122px) {
    .heightMan {
      height: 82vh;
    }

    // .contCss {
    //   height: 94.5%;
    // }

    .contCss {
      height: 96%;
    }

    .contCss100 {
      height: 101.15%;
    }
  }

  .newTabList {
    margin-top: 0px !important;
  }

  // .sidenav{
  //   left: 0 !important;
  //   z-index: 12000 !important;
  // }
  #tableCssDownload {
    .WorkspaceButtonPadding {
      padding: 5px 5px !important;
    }

    .fa-new {
      color: #c2c8d1;
      height: 12px !important;
      width: 12px !important;
    }

    .new-Input-Button {
      height: 25px !important;
    }
  }

  .MuiTab-root {
    min-width: 120px !important;
    opacity: 0.5 !important;
    color: #50D7ED !important;
  }

  .MuiTab-textColorInherit.Mui-selected {
    opacity: 1 !important;
    // border: 2px solid #50D7ED;
    // border-radius: 6px 6px 0 0;
  }

  .MuiTabs-indicator {
    background-color: #50D7ED !important;
  }

  .MuiSvgIcon-root {
    fill: #50D7ED !important;
  }

  .WorkspaceButtonPadding {
    padding: 11px 11px !important;
  }

  .resetButton {
    height: 44px !important;
  }

  .table-Seatch {
    input {
      height: 38px !important;
    }

    a {
      top: 6px
    }
  }

  .entitiedCss {
    margin-left: 62px !important;
  }

  .chatHistoryButton {
    height: 44px !important;
  }

  @media (max-width: 1599px) {
    .resetButton {
      height: 38px !important;
    }

    .entitiedCss {
      margin-left: 55px !important;
    }

    .chatHistoryButton {
      height: 38px !important;
    }

    .table-Seatch {
      input {
        height: 34px !important;
      }

      a {
        top: 6px
      }
    }
  }
}

input {
  // flex: 1;
  // padding: 8px;
  padding: 8px 8px 8px 38px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  // cursor: pointer;
  background-color: #33D6FF;
}

.feedbackCss {
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 5px;
}

.MessIconPos {
  position: relative;
  float: right;
  left: 61px;
}

.MessIconPos1 {
  position: relative;
  float: right;
  left: 25px;
}

.backButtonCss {
  height: 25px;
  width: 25px;
  background-color: #f2f5fd;
  border: 1px solid #50D7ED;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.MessageHov-BioView {
  position: relative;
  cursor: pointer;
}

.MessageInfoHov-BioView {
  display: none;
  z-index: -10;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.MessageHov-BioView:hover>.MessageInfoHov-BioView {
  position: absolute;
  left: 80%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  top: -30px;
  opacity: 1;
  border: .8px solid #333030;
  padding: 5px 10px;
  text-wrap: nowrap;
}

.MessageHov-BioView .MessageInfoHov-BioView::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 42%;
  margin-left: -5px;
  top: 29px;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;

}

.MessageHov-BioView2 {
  position: relative;
  cursor: pointer;
}

.MessageInfoHov-BioView2 {
  display: none;
  z-index: -10;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.MessageHov-BioView2:hover>.MessageInfoHov-BioView2 {
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  top: -30px;
  opacity: 1;
  border: .8px solid #333030;
  padding: 5px 10px;
  text-wrap: nowrap;
}

.MessageHov-BioView2 .MessageInfoHov-BioView2::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 56%;
  margin-left: -5px;
  top: 27px;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;

}

.MessageHov-BioView1 {
  position: relative;
  cursor: pointer;
}

.MessageInfoHov-BioView1 {
  display: none;
  z-index: -10;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}

.MessageHov-BioView1:hover>.MessageInfoHov-BioView1 {
  position: absolute;
  left: 2%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  top: -37px;
  opacity: 1;
  border: .8px solid #333030;
  padding: 5px 10px;
  text-wrap: nowrap;
}

.MessageHov-BioView1 .MessageInfoHov-BioView1::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 64%;
  margin-left: -5px;
  top: 29px;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;

}

.tableInsideElement {
  tr:first-child {
    border-top: none !important;
  }
}