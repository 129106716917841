.site-footer-authentication {
    height: var(--footer-height);
    display: flex;
    align-items: center;
    color:#fff!important;
    background: transparent;
    z-index: 3;
    position: absolute;
    bottom: 0px;
    opacity: 0.7;
  
    .footer-link,
    .copyright-text {
      color: #fff !important;
      font-size: 10px !important;
      font-weight: 400 !important;
      text-decoration: none !important;
      line-height: 20px;
    }
  .FooterLogo{
width: 115px;
height: auto;
  }
    .footer-link {
      padding: 0 0.5rem;
      font-size: 10px;
      border-right: 2px solid #fff;
      color: #fff !important;
      &:last-child {
        border-right: none;
      }
    }
    @media  (min-width:1920px) {
    .FooterLogo{
        width: 130px;
    }
    .copyright-text,.footer-link{
        font-size: 12px!important;
    }
    }
  }