.main-layout {

  background: #fff;

  .site-navigation-panel {
    position: fixed;
    z-index: var(--z-index-site-navigation-panel);
    top: 0;
    left: 0;
    height: 100vh;
    width: var(--site-navigation-panel-width);

    cursor: pointer;
    // background: var(--color-fg-primary);
    background: #fff;
    transition: width 200ms ease-in-out;

    &:hover,
    &.is-locked {
      width: var(--site-navigation-panel-width-hover) !important;
      transition: width 250ms ease-in-out;

      &~.main-layout__main,
      &~.site-footer {
        // margin-left: var(--site-navigation-panel-width-hover) !important;
      }
    }

    &.is-locked {

      &~.main-layout__main,
      &~.site-footer {
        // margin-left: var(--site-navigation-panel-width-hover) !important;
      }
    }
  }

  &__main,
  .site-footer {
    margin-left: var(--site-navigation-panel-width);
    transition: margin-left 400ms ease-in-out;
  }

  &__main {
    // min-height: 100vh;
    background-color: #F4F7FC;
    min-height: calc(100vh - var(--footer-height));
    margin-top: 64px;
    // overflow: auto;
  }

  .site-navigation-panel {
    pointer-events: auto;

    .nav-link__title {
      pointer-events: none;
    }
  }

}