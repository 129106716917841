.ExcelTable2007 {
  border: 1px solid #DBDEE4;
  background-color: #f4f7fc!important;
  // border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  // font-weight: 100;
  // border-spacing: 0px;
  // border-collapse: collapse;
  padding: 30px;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .ExcelTable2007 {
    border: 1px solid #DBDEE4;
    // border-width: 1px 0px 0px 1px;
    font-size: 11pt;
    // font-weight: 100;
    // border-spacing: 0px;
    // border-collapse: collapse;
    padding: 30px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  tr:nth-child(2) td {
    background: #F9FAFD !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    color: #687182;
  }
  tr td:first-child{
    display: none;
  }
  tr:first-child {
    display: none;
  }
  th {
    background-repeat: repeat-x;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background-color: #6C4CDE;
    
  }

  td {
    // background-color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 13px!important;
    line-height: 140%!important;
    vertical-align: middle;
    color: #111827!important;
    border-top: 1px solid #DBDEE4!important;
    padding: 0.75rem!important;
    //border: 1px solid #fdd567;
  }

tbody{
  border: 1px solid #DBDEE4!important;
  background-color: #fff!important;
}
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #111827;
    //width: 150px;
    white-space: nowrap;
    text-align: center;
    padding: 10px;
    height: 32px;
    font-size: 12px;
    // width: 100vw;
  }
  tr:nth-child(even) {

  }
  tbody::-webkit-scrollbar {
    height: 10px;
  } 
}
.ExcelTable2007{
  height: 100vh;
  padding: 20px;
  overflow-x: auto !important;
}
// .modal-ExcelPreview-popup {
//   max-width: calc(80vw - 1rem) !important;
// }
// .modal-ExcelPreview-popup .modal-header {
//   padding-left: 20px;
//   padding-right: 20px;
// }
// .ExcelTable2007::-webkit-scrollbar {
//   height: 0.4rem;
// }

// .ExcelTable2007::-webkit-scrollbar-track {
//   background-color: #f1f1f1;
// }

// .ExcelTable2007::-webkit-scrollbar-thumb {
//   background-color: #888; 
// }

// .ExcelTable2007::-webkit-scrollbar-thumb:hover {
//   background-color: #555; 
// }
.ExcelTable2007::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
.ExcelTable2007::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.ExcelTable2007::-webkit-scrollbar-thumb {
  background: #C5C4C8;
  border-radius: 10px;
}