.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 0 12px 13px 0 rgba(0, 0, 0, 0.4);
  border: 0.5px solid rgba(205, 208, 227, 0.65);
  background-color: #fff;
  z-index: 12000;
}

.sidenav a {
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #000;
}

.sidenav .closebtn {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 31px;
  margin-left: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #F0F0F0;
}

.user-info-box {
  padding: 0 20px;
}

.user-info-box .blue-clr {
  color: #82868d !important;
}
.user-info-box .heading-box{
  color:#111827!important;
}

@media screen and (max-height: 450px) {
  #clientrelevantviewPage .sidenav {
    padding-top: 15px;
  }

  #clientrelevantviewPage .sidenav a {
    font-size: 18px;
  }
}

.section-h {
  font-size: 19px;
  color: #111827 !important;
  margin-top: -51px;
  margin-right: 28px;
}

.seperator-nav {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* padding: 1rem 0; */
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  margin-top: 6px;
  height: 10px;
}

.user-info-box {
  padding: 0px 20px;
}

.user-info-box {
  font-size: 16px;
  color: #71748d;
}

@media (min-width: 768px) {
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.allocateBtn {
  margin-top: 1.8rem;
}

p .note-icon {
  width: 17px !important;
  height: auto !important;
  padding: 2px !important;
  cursor: pointer;
}

.headerclass {
  background: var(--color-primary-blue-dark);
}

table thead th {
  color: white;
}

.order-4 {
  color: #687182;
}

.react-bootstrap-table th .order-4:before {
  opacity: 5;
  color: #687182;
}

.react-bootstrap-table th .order-4::after {
  opacity: 5;
  color: #687182;
}

.user-info-box .blue-clr {
  color: #82868d !important;
}

h4.heading-box {
  font-size: 14px;
  color: #111827;
  font-weight: 600;
word-wrap: break-word;
}

.table-responsive-projectlist {
  font-size: 13px;
  padding: 11px;
}

.submitbox.btn.btn-primary {
  font-size: 12px;
  padding: 0 14px;
  height: 33px;
  line-height: 26px;
}

// .reset-button{
//     font-size: 12px;
//     padding: 0 14px;
//     height: 28px;
//     line-height: 26px;
// }
.model-download {
  max-width: min-content;
}

.pageDropdown {
  #pageDropDown {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 12px;
    font-weight: 600 !important;
  }

  #pageDropDown:focus {
    box-shadow: none !important;
  }

  .dropdown-menu {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-width: 1rem !important;
    z-index: 100;

    .dropdown-item {
      padding: unset !important;

      a {
        padding: 0.25rem 1.5rem;
      }
    }
  }

  a {
    font-weight: normal;
    font-size: 14px;
  }
}

#projectPage {
  .search-label {
    width: 100% !important;
  }
}

.d-inherit {
  display: inherit !important;
}

.ml-2point5 {
  margin-left: 2.5rem;
}

.ml-0point1 {
  margin-top: 0.1rem;
}

.searchfieldml {
  margin-left: 1px;
}

.css-1ac4k92-control:hover {
  box-shadow: 0 0 0 0.1rem #d7e4ff;
}

#projectpage .date-range-picker {
  max-width: 200px;
}

#projectpage1 .add-round-button,
.close-round-button {
  --size: 2rem !important;
}

#allocationbutton .input-button {
  padding: 0.4rem 1.5rem;
}

.overflowfix {
  overflow-x: hidden;
}

.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  // background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

.badge-soft-amber {
  color: #fff;
  background-color: #ffbf00;
}

.badge-soft-green {
  color: #fff;
  background-color: #34c38f;
}

.badge-soft-grey {
  color: #fff;
  background-color: #808080;
}

.badge-soft-black {
  color: #fff;
  background-color: #000;
}

.badge-soft-red {
  color: #fff;
  background-color: #f46a6a;
}

.status-amber {
  color: #ffbf00;
}

.status-green {
  color: #34c38f;
}

.status-red {
  color: #f46a6a;
}

.status-grey {
  color: #808080;
}

.status-black {
  color: #000;
}

.delete-icon {
  img {
    height: 20px;
    cursor: pointer;
  }
}

.actnBtns img {
  height: 15px;
}

.calenderBtn:hover {
  cursor: pointer;
}

.deleteBtnScreen {
  width: 48%;
  background-color: #fff;
  border: 1px solid black;
  padding: 0.3rem 1rem;
  border-radius: 6px;
}

.deleteBtnScreen:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.deleteBtnScreen2 {
  width: 48%;
  background-color: #fff;
  border: 1px solid black;
  padding: 0.3rem 1rem;
  border-radius: 6px;

}

.deleteBtnScreen2:hover {
  background-color: #6C4CDE;
  color: #fff;
  border: none;
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentageBar: var(--valueBar);
  --percentage: var(--value);
  --primary: var(primary);
  --secondary: #F0E7E7;
  ;
  --size: 42px;
  width: var(--size);
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  // left: 8px;
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(from 0.75turn at 50% 100%, var(--primary) calc(var(--percentageBar) * 10% / 2), var(--secondary) calc(var(--percentageBar) * 10% / 2 + 0.1%));
  mask: radial-gradient(at 50% 100%, #0000 50%, #000 55.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 50%, #000 55.5%);
}

[role="progressbar"]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) '';
  // font-family: Helvetica, Arial, sans-serif;
  // font-size: calc(var(--size) / 5);
  color: var(--primary);
  font-weight: 600;
  font-size: 12.5px;
  position: relative;
  top: 4.5px;
}

.BlackLabel {
  color: #111827 !important;
  font-size: 14px ;
  opacity: 1 !important;
  font-weight: 500 !important;
}

.StatusDisplay {
  // background: var(--color-secondary-blue);
  width: fit-content !important;
}

.bg-status {
  background:  var(--color-primary-blue);
}
.bg-status1{
  background-color: black;
}
.StatusDisplayText{
  color:  var(--color-primary-blue);
}
.filter-modal {
  min-width: calc(50vw - 10rem) !important;

  .filter-text {
    color: var(--color-text);
    font-weight: 500;
    width: fit-content;
    padding: 15px 15px;
    border: 1px solid white;
    font-size: 16px;

    &:hover {
      color: #50D7ED!important;
      // border: 1px solid rgb(171, 168, 168);
      border-radius: 4px;
      padding: 15px 15px;
      background-color:rgba(120, 84, 247, 0.05);
      // background-color: var(--color-gray-light);
      width: 100%;
      cursor: pointer;

    }
  }

  .modal-content {

    // border: 2px solid #6c4cde;
    // border-radius: 15px;
    // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    //   rgba(0, 0, 0, 0.22) 0px 15px 12px;
    padding: 1rem 1rem;

    .modal-header {
      // border-color: #6c4cde;

      h5 {
        font-size: 22px;
      }
    }

    .modal-body {
      // padding: 1rem 2rem;
      min-height: 30vh;
      padding: 10px 21px 32px 21px;
      .label {
        // font-size: 18px;
        background-color: red;
        color: #6c4cde;
      }

      .form-check {
        width: 50%;

        .form-check-input:checked~.form-check-label {
          color: #111;
        }

        .form-check-input {
          width: 20px;
          height: 20px;
          accent-color: #6C4CDE;
          cursor: pointer;
        }

        .form-check-label {
          font-size: 16px;
        }
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}
.top-10{
padding-top:-5px;
}
.markdeliveredbtn{
  position: relative;
  top:-4px
}
.parent-expand{
  background-color: #f7f5ff;
}
.WorkspaceButtonPadding{
  padding: 0 11px!important;
}

.InfoHov{
  position: relative;
}
.listInfoHov {
  display: none;
  z-index: -10;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.InfoHov:hover>.listInfoHov{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  top: 50px;
  opacity: 1;
  border: 0.8px solid #333030;
  padding: 5px 10px;
  text-wrap: nowrap;
}
.InfoHov .listInfoHov::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  left: 50%;
  margin-left: -5px;
  top:-5px;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid #333030;
}
.downloadabs{
  right: -22px;
}
.allocateabs{
  right:-16px
}
.markdelabs{
  width: 118px;
  right: -38px;
}
.spinnerLoader{
object{
  height: 35px;
  width: 35px;
}
}
.MTag{
position: relative;
// background-color: #FBE6FB;
// color:#aa336a;
// border:1px solid #aa336a;
// border-radius: 6px;
padding: 0rem!important;
span{
  font-size: 10px;
  font-weight: 700;
}
.MonitoringDiv{
  display: none;
}
}
.MTag:hover{
  .fa-caret-up{
    color: #333030;
    font-size: 16px;
    position: absolute;
    top: -10px;
    left: 40%;

  }
  .MonitoringDiv{
    z-index: 2000;
    display: block;
    position: absolute;
    background-color: #333030;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    top: 25px;
    left: -32px;
    border-radius: 6px;
    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.08);
  }
}

.px-pt7rem{
padding-left: 0.5rem;
padding-right: 0.5rem;
}
.RMIcon{
  height: 18px;
  width: 18px;
}
.RTag{
  padding:0rem!important;
  position: relative;
  span{
    font-size: 10px;
    font-weight: 700;
  }
  .RerunDiv{
    display: none;
  }
}
.RTag:hover{
  .fa-caret-up{
    color: #333030;
    font-size: 16px;
    position: absolute;
    top: -10px;
    left: 40%;

  }
  .RerunDiv{
    z-index: 2000;
    display: block;
    position: absolute;
    background-color: #333030;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    top: 25px;
    left: -21px;
    border: 1px;
    border-radius: 6px;
    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.08);
  }
}
.RMTAGWIDTH{
  width: 18px;
}
@media  (min-width:1920px) {
.RMIcon{
  height: 20px;
  width: 20px;
}
.MTag,.RTag{
  width: 20px;
}
  }
.minWidthCustom{
  padding: 0px!important;
}
.BatchRowWidth{
  // min-width: 60px!important;
  max-width: 150px;
  word-wrap: break-word;
}
.CaseIDDiv{
  font-weight: 600;
}
.CreatedOnWidth{
  width: 120px;
}




.HomeInfoHov{
  position: relative;
  height: auto;
  width: auto;
}
.HomelistInfoHov {
  display: none;
  z-index: -10;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}
.HomeInfoHov:hover>.HomelistInfoHov{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  top: 35px;
  opacity: 1;
  border: 0.8px solid #333030;
  padding: 5px 10px;
}
.HomeInfoHov .HomelistInfoHov::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  left: 50%;
  margin-left: -5px;
  top:-5px;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid #333030;
}
.downloadPopup{
  input[type="checkbox"]{
    margin-right: 6px;
  }
}

@media screen and (max-width: 1500px) {
  .sidenav{
    padding-top: 40px;
  }

  h4.heading-box {
    font-size: 12px;
  }
}