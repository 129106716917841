.date-picker {
  &__container {
    display: inline-block;
    width: 100%;
    max-width: 250px;
    background: #fff;

    &--full-width {
      max-width: unset;
    }
  }
  
  .MuiFormControl-marginNormal {
    margin: 0;
  }

  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 0;
  }

  .MuiOutlinedInput-input {
    padding: 12px 5px;
  }

  .MuiIconButton-root {
    padding: 0px;
  }

  font-weight: bold;
  //border-radius: 4px;
}

.picker {
  font-weight: bold !important;
  //height: 100px !important;
}
.MuiInput-root {
  position: relative;
  /* padding: .2rem; */
  margin-top: 0.15rem;
  margin-left: 0.3rem;
}