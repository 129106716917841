// .uncopyable {
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }


.cursor-pointer {
  cursor: pointer !important;
}
.bp3-toast{
    position: fixed !important;
    top: 15px !important;
  }