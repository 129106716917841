.site-header {
  height: var(--app-header-height);
  background: #f2f2f5;
  padding-left: 81px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid #e8e8ea;

  .home-icon-h {
    width: 16px;
  }

  .homeHandleUnselect,
  .homeHandleSelect {
    height: var(--app-header-height);
    border-right: 1px solid #e8e8ea;
    border-radius: unset !important;
  }

  .homeHandleSelect {
    background-color: #F4F7FC;
  }

  .header-link,
  .copyright-text {
    color: gray !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    line-height: 20px;
  }

  .action-h-c {
    margin-left: auto;

    .profile-h {
      // padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 130px;

      .name-badge {
        background:var(--color-primary-blue);
        text-align: center;
        color: #fff;
        padding: 7px;
      }

      .name-badge-img ,.name-badge{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid #dee2e6;
      }
    }

  }

  .tab {
    background-color: unset !important;

    &__logo {
      width: 24px;
      // height: 24px;
      background: #0000000d;
      border-radius: 4px;
      color: #04021d66;
      text-transform: unset;
    }

    &__text {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #04021C;
      opacity: 1;
      font-weight: 600;
      font-size: 14px;
      max-width: 205px;
    }

    &__close {
      opacity: 1;
    }

    .project-card-image {
      width: 20px;

    }
  }

  .Mui-selected {
    color: #000000;
    background: #F4F7FC !important;
    height: 64px !important;
    border: none !important;
  }

  .header-link {
    padding: 0 0.5rem;
    border-right: 1px solid gray;
    color: gray !important;

    &:last-child {
      border-right: none;
    }
  }

  .MuiTabs-indicator {
    background-color: unset !important;
  }

  .MuiTab-root {
    height: 64px;
    border-right: 1px solid #e8e8ea;
    text-transform: unset;
  }

  .MuiTabScrollButton-root {
    width: 20px !important;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  .MuiTabs-scroller {
    overflow: auto hidden;
  }
  .Header_date{
    margin-right: 8px;
    font-size: 13px;
    font-weight: 600;
    color:  #111827;
  }
}