.aml-logo {
  &__container {
    display: inline-block;
    width: 64px;
    height: 48px;
    cursor:pointer;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    @include filter-white;
  }
}
