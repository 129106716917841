.date-range-picker {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 250px;

  &.full-width {
    max-width: unset;
  }

  .custom-filter-wrap {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9999;
  }

  .rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 1000;
    right: inherit !important;
    width: 450px;
    max-width: 100vw;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  .hide-calendar {
    display: none;
  }

  &__toggle-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 2.5rem;
    overflow-y: hidden;
    overflow-x: auto;
    font-size: 14px !important;
    // font-weight: 500 !important;
    // height: 2.4rem;
    height: 2.1rem;
    background: #fff !important;
    border-color: hsl(0, 0%, 80%) !important;

    &__text {
      flex: 1;
      text-align: left;
      margin-left: 0.5rem;
    }

    &__text,
    &__icon {
      color: #555 !important;
    }
  }

  .hide-to {
    .rdrDateDisplayItem:nth-child(2) {
      display: none;
    }

    .rdrStaticRange:nth-child(5) {
      border-bottom: 1px solid #c3c3c3;
    }
  }
}

.NewDateRangePicker {
  .custom-filter-wrap {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 0;
  }

  .custom-filter-wrap {
    position: inherit !important;
  }

  .rdrMonthAndYearWrapper {
    // padding-top: unset;
    height: 40px;
  }
  
  .rdrMonthName{
    padding: 0 13px 13px 13px;
  }

  .rdrDateRangePickerWrapper {
    position: initial !important;
    width: 50% !important;
    z-index: 0;
    box-shadow: none;
    margin: auto;
  }

  .rdrCalendarWrapper {
    width: 100% !important;
    padding: 10px 15px;
    border-radius: 0 20px 20px 0;
  }

  .rdrMonth {
    width: 100%;
    padding: 16px 16px 0 16px;
  }

  .rdrDefinedRangesWrapper {
    width: 30%;
  }

  .rdrStaticRange {
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: none;
    padding: 0;
    background: transparent !important;
  }

  .hide-to .rdrStaticRange:nth-child(5) {
    border-bottom: none !important
  }

  .rdrStaticRangeLabel {
    display: block;
    outline: 0;
    line-height: 22px;
    padding: 6px 20px;
    text-align: left;
  }

  .rdrDefinedRangesWrapper {
    background-color: #fff;
    font-size: 14px;
    color: #3e4451;
    font-weight: 400;
    border-radius: 20px 0 0 20px;
    padding-top: 15px;
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel{
    // background: rgba(120, 84, 247, 0.05);
    background: #50D7ED !important;
  }

  .rdrDay {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
  }

  .rdrDayToday {
    background-color: transparent;
  }

  .rdrDayHovered {
    border: none;

    .rdrDayEndPreview {
      border: none;
      border-radius: 0px;
    }

    .rdrDayStartPreview {
      border: none;
      border-radius: 0px;
    }
  }

  .rdrMonthAndYearPickers select:hover {
    background-color: transparent;
  }

  .rdrDayInPreview,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border: none;
  }

  .rdrInRange {
    background-color: #50D7ED !important;
  }

  .rdrSelected,
  .rdrStartEdge,
  .rdrEndEdge {
    background: #50D7ED !important;
    background-color: #50D7ED !important;
    border: 1px solid #50D7ED!important;
  }
.rdrStartEdge{
  border-top-left-radius: 6px!important;
  border-bottom-left-radius: 6px!important;
}
.rdrEndEdge{
  border-top-right-radius:  6px!important;
    border-bottom-right-radius: 6px!important;
}
  .rdrDayNumber {
    color: #111827;
    font-weight: 600;

    span {
      width: 100%
    }

    ;
  }
}

.Button-noDisplay {
  .rdrDateDisplayWrapper {
    display: none;
  }

  .date-range-picker__toggle-button {
    display: none;
  }
}


@media screen and (max-width: 1500px) {

  .date-range-picker {
    &__toggle-button {
      font-size: 12px !important;
    }
  }

  .NewDateRangePicker {
    // .rdrCalendarWrapper {
    //   padding: 1rem 1.5rem;
    // }

    .rdrDay {
      // height: 3.4rem;
      // line-height: 3.4rem;
      font-size: 14px;
      // font-weight: 600;
      // background-color: transparent;
    }

    // .rdrMonthAndYearPickers {
    //   justify-content: start;
    // }
  }
}

.calenderClose{
  position: absolute;
}

.calenderButtonClose{
  position: relative;
  z-index: 1000;
  top: 370px;
  left: 375px;
}

.calenderButtonClose1{
  position: relative;
  z-index: 1000;
  top: 370px;
  right: -160px;
}
