.InnerTable table th {
    color: #000000;
    background-color: #ffffff;
    font-weight: 700;
    // border: 1px solid #dee2e6 !important;
  }

  .InnerTable{
    min-height: auto!important;
  }
  #inSrcBtn{
    .btn-primary{
      padding: 0 14px !important;
    }
  }
  #CaseViewPage .row-expansion-style {
    padding: 0.75rem;
    margin-top: 0.7rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
    border: 0.5px solid #ddddddc4;
    background-color: #fff;
  }
  .fa-disabled {
      opacity: 0.6;
      cursor: not-allowed!important;
    }
//   #workspace .react-bootstrap-table thead th:nth-child(2) { width:30px;}
//   #workspace .react-bootstrap-table thead th:nth-child(2) { width:30px;}
//   #workspace .react-bootstrap-table thead th:nth-child(3) { 
//       min-width: 170px ;
//   }
  
  // #workspace .react-bootstrap-table tr td:nth-child(4) { 
  //   max-width: 300px!important ;
  //   word-wrap: break-word;
  // }
  //   #workspace .react-bootstrap-table tr td:nth-child(3) { 
  //   max-width: 300px!important ;
  //   word-wrap: break-word;
  // }
  // #AllocationPage  .react-bootstrap-table tr td:nth-child(2) { 
  //   max-width: 300px;
  //   word-wrap: break-word;
  // }
  // #liveScanSearch .customBG th{
  //   min-width: 30px;
  // }
  // #workspace .customBG th{
  //   min-width: 30px;
  // }
  // #delivery .customBG th{
  //   min-width: 30px;
  // }
  // #AllocationPage .customBG th{
  //   min-width: 30px;
  // }
  //   #workspace .react-bootstrap-table  td:nth-child(4) { 
  //  width: 135px ;
  // }
  // #delivery .react-bootstrap-table  td:nth-child(4) { 
  //   width: 135px ;
  //  }
  // #AllocationPage .react-bootstrap-table  td:nth-child(3) { 
  //   width: 135px ;
  //  }
  //  #AllocationPage  .InnerTable.react-bootstrap-table thead th:nth-child(2) { 
  //   width: 130px!important;
  // }
  // #AllocationPage  .InnerTable.react-bootstrap-table thead th:nth-child(3) { 
  //   width: 400px!important;
  // }
  // #AllocationPage  .InnerTable.react-bootstrap-table thead th:nth-child(4) { 
  //   width: 135px!important;
  // }
//   #workspace .react-bootstrap-table thead th:nth-child(5) { 
//       min-width: 110px ;
//   }
//   #workspace .react-bootstrap-table thead th:nth-child(5) { 
//       min-width: 150px ;
//   }
//   #workspace .react-bootstrap-table thead th:nth-child(6) { 
//       min-width: 200px ;
//   }
//   #workspace .react-bootstrap-table thead th:nth-child(12) { 
//       min-width: 150px ;
//   }
//   #workspace .react-bootstrap-table  .InnerTable thead th:nth-child(1) {
//    width:30px
//    }
//    #workspace .react-bootstrap-table  .InnerTable thead th:nth-child(2) {
//    width:30px
//    }
//   #workspace .react-bootstrap-table  .InnerTable thead th:nth-child(3) {
//    min-width:170px
//    }
  
//   #workspace .react-bootstrap-table  .InnerTable thead thead th:nth-child(3) { 
//      min-width: 200px ;
//   }
//   #workspace .react-bootstrap-table  .InnerTable thead th:nth-child(4) { 
//      min-width: 200px ;
//   }
//   #workspace .react-bootstrap-table  .InnerTable thead th:nth-child(5) { 
//      min-width: 200px ;
//   }
//   #workspace .react-bootstrap-table  .InnerTable thead th:nth-child(7) { 
//       min-width: 200px ;
//   }
  
// #delivery .react-bootstrap-table  tr td:nth-child(4) { 
//   max-width: 220px!important ;
//   word-wrap: break-word;
//   }
//   #delivery .react-bootstrap-table  tr td:nth-child(7) { 
//     max-width: 178px!important ;
//     word-wrap: break-word;
//     }
//   #delivery .react-bootstrap-table .customBG th{
//     min-width: 30px;
//   }
  
//   #workspace .InnerTable .NewTable td:nth-child(4){
//  width: auto!important;
//  } 
//  .InnerTable .NewTable td:nth-child(2){
//   max-width: 200px!important;
//   word-wrap: break-word;
//   } 
//   .InnerTable .NewTable td:nth-child(3){
//     max-width: 250px!important;
//     word-wrap: break-word;
//     } 
//    #workspace  .InnerTable .NewTable td:nth-child(4){
//       max-width: inherit!important;
//       } 
    
  
//   #delivery .react-bootstrap-table thead th:nth-child(2) { width:30px;}
//   #delivery .react-bootstrap-table thead th:nth-child(2) { width:30px;}
//   #delivery .react-bootstrap-table thead th:nth-child(3) { 
//       min-width: 170px ;
//   }
  
//   #delivery .react-bootstrap-table thead th:nth-child(4) { 
//      min-width: 200px ;
//   }
  
//   #delivery .react-bootstrap-table thead th:nth-child(5) { 
//       min-width: 110px ;
//   }
//   #delivery .react-bootstrap-table thead th:nth-child(6) { 
//       min-width: 150px ;
//   }
//   #delivery .react-bootstrap-table thead th:nth-child(7) { 
//       min-width: 200px ;
//   }
//   #delivery .react-bootstrap-table thead th:nth-child(13) { 
//       min-width: 150px ;
//   }
//   #delivery .react-bootstrap-table  .InnerTable thead th:nth-child(1) {
//    width:30px
//    }
//    #delivery .react-bootstrap-table  .InnerTable thead th:nth-child(2) {
//    width:30px
//    }
//   #delivery .react-bootstrap-table  .InnerTable thead th:nth-child(3) {
//    min-width:170px
//    }
  
//   #delivery .react-bootstrap-table  .InnerTable thead thead th:nth-child(4) { 
//      min-width: 200px ;
//   }
//   #delivery .react-bootstrap-table  .InnerTable thead th:nth-child(5) { 
//      min-width: 200px ;
//   }
//   #delivery .react-bootstrap-table  .InnerTable thead th:nth-child(6) { 
//      min-width: 200px ;
//   }
//   #delivery .react-bootstrap-table  .InnerTable thead th:nth-child(7) { 
//       min-width: 200px ;
//   }
  // #batchupload .react-bootstrap-table thead th:nth-child(10) { 
  //     min-width: 150px ;
  // }
  // #batchupload .react-bootstrap-table thead th:nth-child(11) { 
  //     width: 60px ;
  // }
  
  
  
  
  
 
  // #liveScanSearch .react-bootstrap-table thead th:nth-child(4) { 
  //     min-width: 170px ;
  // }
  
  // #liveScanSearch .react-bootstrap-table thead th:nth-child(5) { 
  //    min-width: 200px ;
  // }
  
  // #liveScanSearch .react-bootstrap-table thead th:nth-child(6) { 
  //     min-width: 150px ;
  // }
  // #liveScanSearch .react-bootstrap-table thead th:nth-child(7) { 
  //     min-width: 150px ;
  // }
  // #liveScanSearch .react-bootstrap-table thead th:nth-child(9) { 
  //     min-width: 150px ;
  // }
  // #liveScanSearch .react-bootstrap-table thead th:nth-child(12) { 
  //     min-width: 150px ;
  // }
  // #liveScanSearch .react-bootstrap-table  .InnerTable thead th:nth-child(1) {
  //  width:30px
  //  }
  //  #liveScanSearch .react-bootstrap-table  .InnerTable thead th:nth-child(2) {
  //  width:30px
  //  }
  // #liveScanSearch .react-bootstrap-table   tr td:nth-child(3) {
  //  max-width:270px;
  //  word-wrap: break-word;
  //  }
   .ActionButtonWidth{
    width: 50px;
  }
  // #liveScanSearch .react-bootstrap-table  .InnerTable thead thead th:nth-child(4) { 
  //    min-width: 200px ;
  // }
  // #liveScanSearch .react-bootstrap-table  .InnerTable thead th:nth-child(5) { 
  //    min-width: 200px ;
  // }
  // #liveScanSearch .react-bootstrap-table  .InnerTable thead th:nth-child(6) { 
  //    min-width: 200px ;
  // }
  // #liveScanSearch .react-bootstrap-table  .InnerTable thead th:nth-child(7) { 
  //     min-width: 200px ;
  // }
  .InnerTable .table-hover tbody tr:hover{
    background-color: #4169E10f;
  }
  .max-wdth-innerTable{
  width:230px!important;
    word-wrap: break-word;
  }
  .InfoHov-BioView{
    position: relative;
    cursor: pointer;
  }
  .listInfoHov-BioView {
    display: none;
    z-index: -10;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
  }
  .InfoHov-BioView:hover>.listInfoHov-BioView {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333030;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    z-index: 999;
    display: flex;
    top: -35px;
    opacity: 1;
    border: .8px solid #333030;
    padding: 5px 10px;
    text-wrap: nowrap;
  }
  .InfoHov-BioView .listInfoHov-BioView::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    margin-left: -5px;
    top: 29px;
    border-width: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333030;

  }
  
  .cursor-notallowed{
    cursor: not-allowed!important;
  }
  .bg-status1{
    background-color: black;
  }
  .bg-statusT{
    background-color: #B166C9;
  }
  .StatusDisplayText1{
    color: #B166C9;
  }

  .bg-statusTF{
    background-color: #c96c66;
  }
  .StatusDisplayText2{
    color: #c97b66;
  }
  .InactiveStatus{
    opacity: 0.8;
    .bg-status{
      background-color: #a4abbc;
    }
    .StatusDisplayText{
      color: #a4abbc;
    }
  }
  
    