#workspace-filter {
  .custom-search-container {
    height: 40px;
  }

  // height: 400px;

  .active-option {
    background-color: rgba(120, 84, 247, 0.05);
    color: #50D7ED !important;
    border: none;
    border-radius: 4px;
    width: 100%;
  }

  .filter-opt {
    &>input {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      border-radius: 10px;
      flex-shrink: 0;

    }


  }
}
.caseoutcomeFilter{
  .picky__dropdown {
    width: 120px!important;
  }
  .box{
    width: 13px;
    height: 13px;
  }
}
.FilterWorkspace {
  .picky__dropdown {
    width: 240px;
    margin-top: 10px;
    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.08);
    // border: 1px solid rgba(17, 24, 39, 0.2);
    padding: 5px;
    border-radius: 6px;
    z-index: 98;
  }

  .picky__dropdown::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
  }

  /* Track */
  .picky__dropdown::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  .picky__dropdown::-webkit-scrollbar-thumb {
    background: #C5C4C8;
    border-radius: 10px;
  }

  .picky__dropdown::-webkit-scrollbar-thumb:hover {
    background: #C5C4C8;
  }

  .picky__placeholder {
    text-overflow: ellipsis;
  }
}

.noborderradius {
  .picky__dropdown .option input {
    border-radius: 4px !important;
  }
}

.font-13-filter {
  font-size: 13px !important;
}

.height-Auto {
  height: fit-content;
}


@media screen and (max-width: 1500px) {
  .FilterWorkspace{
    .picky__dropdown{
      margin-top: 5px;
    }
  }
}
.filterbox{
  width: 12px;
  height: 12px;
}
.TransactionAction{
  .picky__input span{
    max-width: 135px!important;
  }
}
.picky[aria-expanded="true"]{
  .picky__input::after{
    transform: rotate(-225deg)!important;
    top: 19px!important;
  }
}