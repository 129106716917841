.bell-icon-wrapper {
  float: right;
}
.notification-icon-container {
  padding: 20px;
  cursor: pointer;
  border-right: 1px solid #e8e8ea;
  border-left: 1px solid #e8e8ea;
  height: 100%;
}
.notifyClearButton {
  color: #111827;
  display: flex;
  align-items: center;
}
.notifyBell
{
  width: 24px;
}
.notifyClearButton .btn {
  float: right;
  color: #6c4cde;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin-left: auto;
}
.notifyClearButton .btn:hover {
  color: #6c4cde !important;
  text-decoration: underline;
}
.bell-icon-badge {
  position: absolute;
  top: -11px;
  min-width: 20px;
  right: -7px;
  height: 20px;
  // padding: 1px 0px;
  border-radius: 50%;
  background-color: #f74141;
  border:1px solid #f74141;
  color: #fff;
  // padding: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
sup{
  font-size: 10px;
  top: -1px;
}
}
.plus99{
  top: -10px;
  min-width: 20px;
  right: -7px;
  height: 20px;
}

.notifications-container {
  position: absolute;
  width: 450px;
  height: 500px;
  overflow-y: auto;
  z-index: 1;
  top: 70px;
  background-color: #fff;
  border-radius: 6px;
  right: 80px;
  box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.08);
  // border: 1px solid #11182733;
}

.notification-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.notifications-container::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

/* Track */
.notifications-container::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.notifications-container::-webkit-scrollbar-thumb {
  background: #C5C4C8;
  border-radius: 10px;
}

.notifications-container::-webkit-scrollbar-thumb:hover {
  background: #C5C4C8;
}

.notification-wrapper {
  padding: 15px;
  margin: 0;
}
.dotFix{
  padding-top: 12px;
}

.notification:last-child{
  border-bottom: none;
}

.notification {
  list-style: none;
  margin-top: 5px;
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-radius: 6px;
  // display: flex;
}

.unread-fy { 
  background-color: #ede9fb;
}

.notification span {
  font-size: 0.75rem;
}
.notification-text {
  font-size: 13px;
  font-weight: 400;
  color: #3d4043;
  padding: 5px 0px;
}
.react-confirm-alert-button-group > button:first-child {
  background: #6c4cde;
}
.unread {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #50D7ED;
  align-self: center;
}
