.input-select {
  &__container {
    display: inline-block;
    width: 100%;
    max-width: 250px;
    z-index: 500 !important;

    &--full-width {
      max-width: unset;
    }
  }

  &__placeholder,
  &__single-value,
  &__option {
    font-size: 14px !important;
  }

  &__menu {
    z-index: 500 !important;
  }

  &__indicator-separator {
    opacity: 0;
  }

  &__dropdown-indicator {
    color: #555 !important;
  }
}
