.reset-button {
  padding: 0.5rem 1rem;
  background: none !important;
  border: none !important;
  outline: none !important;
  color: var(--color-sky-blue);
}
.resetButton{
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  height: 44px;
  width: 44px;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #50D7ED;
  /* background-clip: padding-box; */
  border-radius: 0.4rem;
}

.add-round-button,
.close-round-button {
  --size: 2.5rem;
  --color: var(--color-secondary-purple);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  color: #fff;
  font-size: 16px;
  background: var(--color);
  outline: none !important;
  border: 1px solid var(--color);
  border-radius: 555rem;
}

.close-round-button {
  --color: red;
  background: none !important;
  color: var(--color);
}

.reset-button-square {
  --size: 2.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 5px;
  outline: none !important;

  &__icon {
    color: var(--color-primary-blue) !important;
  }
}

@media screen and (max-width: 1500px) {
  .resetButton  {
    height: 38px;
    width: 38px;
  }
}
