#settingPage {
  .col-md-3 {
    padding: 0;
  }

  .info-all-scoring {
    right: 25px;
    top: 2px;
  }

  .card-body {
    flex: 1 1 auto;
    // height: 650px;
    // overflow-y: scroll;
    padding: unset;
    padding-top: 8px !important;
    padding-bottom: 24px;
  }

  .scoreSectionCss {
    margin-top: 24.5px !important;
    padding-bottom: 24px;
  }

  .outcomeCss {
    padding-top: 8.5px !important;
    padding-bottom: 24px;
  }

  .card-header {
    background-color: #6c4cde;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }

  .rangeType div {
    font-size: 12px;
    text-align: center;
    display: inline-block;
  }

  .alert-primary {
    color: #f7f7f7;
    background-color: #f6f6f7;
    border-color: #1118271A;
    border-radius: 8px;
    border: unset;

    .main-span {
      color: #687182;

      .ex-span {
        display: block;
      }
    }
  }

  .group-next-icon {
    img {
      width: 14px;
      height: 14px;
    }
  }

  .alert-primary pre {
    color: #687182;
    font-family: "Open Sans";
  }

  .fa-info-circle {
    color: #a0a3a9;
  }

  .settingInfoIcon {
    float: right;
    display: inline-block;
  }

  // ::-webkit-scrollbar {
  //   height: 5px;
  //   width: 4px;
  // }

  // /* Track */
  // ::-webkit-scrollbar-track {
  //   background: #fff;
  // }

  // /* Handle */
  // ::-webkit-scrollbar-thumb {
  //   background: #C5C4C8;
  // }

  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #C5C4C8;
  // }

  .fa-external-link {
    color: #6C4CDE;
    padding-top: 10px;

  }

  .fontpt9rem .MuiTypography-body1 {
    font-size: 15px;
  }

  .save-btn {
    top: 60px;
    right: 25px;
  }

  .scoring-card {
    border: 1px solid #E2E3E7;
    min-height: 396px;

    .card-name {
      height: 50px;
      background-color: #f5f2ff;
      border-bottom: 1px solid #E2E3E7;
      color: #111827;
    }

  }

  .MuiFormControlLabel-root {
    margin-left: 0px !important;
  }

  .grid-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .grid-section {
      background-color: #fff;
      padding: 30px 20px;
      border-bottom: 1px solid #d8d8d8;

      &:nth-child(odd) {
        border-right: 1px solid #d8d8d8;
      }

      .MuiFormControlLabel-root {
        margin-right: 0 !important;
      }

      label {
        margin-bottom: 0;
      }

      .row {
        margin: 0;
      }
    }

    .custom-slider {
      .bar {
        .thumb::before {
          margin: -6px -6px !important;
        }
      }

      .caption {
        left: -2px !important;
      }

      .labels {
        .label {
          &:first-child {
            &:before {
              content: '0';
              color: #3CA774;
            }

            color: transparent;
          }

          &:last-child {
            margin-right: -12px;
          }
        }
      }
    }
  }



  // input[type="checkbox"] {
  //   display: none;
  // }

  .borderbox {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .padIcon {
    padding-top: 11px;
  }

  .disabledIcon {
    color: #8b9dc3 !important;
  }

  .slider-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1px;

    .grid-cell {
      padding: 20px;
      background-color: #fff;
    }

    .grid-cell:nth-child(odd) {
      border-right: 1px solid #0000001a;
    }

    .MuiSlider-thumb.MuiSlider-active {
      box-shadow: none;
    }


  }



  @media screen and (max-width: 1500px) {
    .grid-container {
      .grid-section {
        padding: 25px 15px;
      }
    }
  }

}

.wg-av-grid-container::-webkit-scrollbar {
  height: 8px;
  width: 5px;
}

.wg-av-grid-container::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.wg-av-grid-container::-webkit-scrollbar-thumb {
  background: #C5C4C8;
  border-radius: 10px;
}

.wg-av-grid-container::-webkit-scrollbar-thumb:hover {
  background: #C5C4C8;
}

.white-bg {
  background-color: #fff;
}

.PurpleBG {
  color: #6C4CDE;
}

.abc {
  opacity: 0.5;
}

.apcCheck {
  margin-left: 12px;
  padding-top: 12px;
}

.allotedW-box {
  height: 34px;
  width: 174px;
  padding: 6px 15px;
  background-color: #f2eeff;
  color: #50D7ED;
  top: -41px;
  left: 190px;

  &.warning {
    background-color: #fdefef;
    color: #EA5D5D;
  }
}

.check-flag-container {
  margin-left: auto;
  object-fit: contain;
  width: 30px;
  height: 30px;
  // background-color: rgb(227, 226, 226);
}

#setting-page {
  #single-select-dp {
    width: 300px;
  }
}

#settingConfirm {
  .new-Input-Button {
    width: 70px !important;
  }
}

.wg-av-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 800px;
  margin: 45px auto;
  max-height: 350px;
  overflow-y: auto;
  padding-right: 10px;

  .grid {
    padding: 20px;
    color: #111827;
    border-bottom: 1px solid #d8d8d8;

    &:nth-child(odd) {
      padding-left: 0;
      border-right: 1px solid #d8d8d8;
    }

    &:nth-child(even) {
      padding-right: 0;
    }

    .text {
      width: 255px;
    }

    .pre-box {
      height: 44px;
      width: 62px;
      background-color: #f7fcfa;
      border: 1px solid #3CA774;
      color: #3CA774;

      input {
        color: #3CA774;
        background: none;
        border: none;
        outline: none;
        width: 28px;
      }
    }
  }
}



.apcModal {
  max-width: 420px;
  border-radius: 20px;
  overflow: hidden;
  border-radius: 15px;
  margin-top: 30vh;
  // min-width: calc(15vw ) !important;
}

.apcModal .modal-body {
  padding: 2rem 3rem;
  border-radius: 15px;
}

.disableLibrary {
  filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(448%) hue-rotate(187deg) brightness(93%) contrast(91%);
}

.MuiTypography-body1 {
  font-size: 16px !important;
}

.radios-container {
  position: absolute;
  top: 0;
  right: 45px;
}

.radioc {
  .radios-container {
    position: unset;
  }
}

.select-all-s {
  padding-left: 20px;
}

.hide-max .labels .label:last-child {
  visibility: hidden;

}

.hide-min .labels .label:first-child {
  visibility: hidden;
}


@media screen and (max-width: 1500px) {
  .MuiTypography-body1 {
    font-size: 14px !important;
  }

  .select-all-s {
    padding-left: 15px;
  }
}