.input-text-field {
  padding: 0.5rem 0.75rem;

  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.3px;

  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1.3px #e2e2ea;
  outline: none !important;

  &:placeholder {
    color: #999999;
  }

  &.is-disabled {
    pointer-events: none;

    color: #555 !important;
    background-color: #E8E8E8 !important;

    &:placeholder {
      color: #555;
    }
  }
}
