:root {
  --z-index-site-navigation-panel: 6000;
  --site-navigation-panel-width: 81px;
  --site-navigation-panel-width-hover: 260px;
  --app-header-height: 64px;
  --footer-height: 3.5rem;

}

.border-round {
  border-radius: 555rem;
}
