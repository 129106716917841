.site-navigation-panel {
  //--site-sidebar-width var is in layouts file
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 10px 0px;
  // box-shadow: 4px 0 8px 2px rgba(0, 0, 0, 0.1);
  background: var(--color-primary-blue);

  &__logo-container {
    height: 64px;
    background: var(--Gradient, linear-gradient(90deg, #50D7ED 11.54%, #B166C9 84.3%));
  }

// .li-tag{
//   margin: 0px 8px;
// }

  .sub-link {
    .mb-10 {
      margin-bottom: 10px;
    }

    .dot-nev {
      width: 6px !important;
      height: 6px !important;
      border-radius: 2px;
      background: rgba(17, 24, 39, 0.10);
    }

    &.active {
      .dot-nev {
        background: #50D7ED;
      }

      span {
        color: #50D7ED;
      }
    }
  }

  .company-container {
    &__open {
      // display: none;
      padding: 24px 20px 15px 20px;
      // margin-bottom: 8px;
      border-bottom: 1px solid rgba(17, 24, 39, 0.20);

      .inner-site-brand-logo-img {
        height: 42px;
        width: 42px;
        margin: auto auto;
      }

      .nav-link__projectName {
        opacity: 0;
        // max-width: 0px;
        max-height: 45px;
        // transition: max-height 10ms linear 250ms, max-width 10ms linear 250ms, opacity 10ms linear 350ms;
      }
      .NavProjectName{
        height: 45px!important;
        // margin: 8px!important;
        margin-top: 0px!important;
        opacity: 0;
      }
    }

    // &__close {
    //   display: block;
    //   padding: 24px 20px;
    //   border-bottom: 1px solid rgba(17, 24, 39, 0.20);

    //   .inner-site-brand-logo-img {
    //     height: 42px;
    //     width: 42px;
    //   }
    // }
  }

  .nav-container {
    padding: 24px 20px 0 20px;
  }

  .list-name {
    color: rgba(17, 24, 39, 0.40);
    // border-bottom: 1px solid rgba(17, 24, 39, 0.10);
  }

  .scroll-container {
    height: calc(100vh - 270px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }


  .collapse {
    max-height: 0px;
    opacity: 0;
  }


  &:hover,
  &.is-locked {
    .brand-logo {
      &-container {
        // background: var(--color-primary-blue);
        // border-bottom-right-radius: 44px;
      }
    }
    
    .company-container {
      &__open {
        .inner-site-brand-logo-img {
          // height: 90px;
          // width: 90px;
          margin: auto auto;
          // transition: height 250ms linear 350ms, width 250ms linear 350ms;
        }
        .NavProjectName{
          display: flex!important;
          opacity: 1;
          height: 45px!important;
          // margin-top: 8px;
          transition: opacity 250ms ease-in-out!important;
        }
        .nav-link__projectName {
          opacity: 1;
          // max-width: 100%;
          height: 45px!important;
          transition:  opacity 250ms linear 350ms;
        //  overflow: hidden;
        //  text-overflow: ellipsis;
        }
      }

      // &__close {
      //   display: none;
      // }
    }

    .nav-link {
      justify-content: start;
    }

    .collapse {
      max-height: 555rem;
      opacity: 1;
      transition: max-height 10ms linear 250ms, opacity 250ms ease-in-out 250ms;
    }


    .nav-link__title {
      max-width: 555rem;
      opacity: 1;
      transition: max-width 10ms linear 250ms, opacity 250ms ease-in-out 250ms;
    }

    .logo-white-clor {
      color: #fff !important;
    }

    .site-navigation-panel-lock__container {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover:not(.is-locked) {
    .inner-site-brand-logo-img {
      //   transform: rotateZ(180deg);
      //   transition: transform 400ms ease-in-out;
    }
  }

  .inner-site-brand-logo {
    &-container {
      width: var(--site-navigation-panel-width);
      height: var(--app-header-height);
      padding: 1rem 0.5rem;
      // text-align: center;
      //   background: rgba(255, 255, 255, 0.04);
      //   transition: border-radius 250ms ease-in-out, background 250ms ease-in-out;
    }

    &-img {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      object-fit: contain;
      border-radius: 2px;
      box-shadow: inset;

      //   padding: 0.5rem;
      //   border-radius: 555px;
      //   background: #fff2;

      //   transition: transform 0ms;
      // @include filter-white;
    }
  }

  .site-navigation-panel-lock {
    &__container {
      position: absolute;
      right: 1.5rem;
      top: 1.55rem;

      pointer-events: none;

      cursor: pointer;
      opacity: 0;
      transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    }

    &__toggle-input,
    &__icon-unlock.hidden,
    &__icon-lock.hidden {
      display: none;
    }

    &__icon-unlock,
    &__icon-lock {
      pointer-events: none;
    }

    &__icon-lock {
      color: var(--color-theme-primary);
      text-shadow: 0 0 4px 1px var(--color-theme-primary);
    }

    &__toggle-input-label {
      --size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--size);
      width: var(--size);

      pointer-events: none;
      opacity: 0;

      cursor: pointer;
      border-radius: 555rem;
      background: rgba(255, 255, 255, 0.04);
      transition: background 180ms ease-in-out;

      &:hover {
        background: rgba(255, 255, 255, 0.07);
      }
    }
  }

  &__nav {
    // height: calc(100vh - 5rem);
    width: 100%;


  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .accordion-head {
    background-color: #fff;
    border: unset;
  }



  .nav-link {
    // --padding-horizontal: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: unset !important;
    color: #fff;
    opacity: 1;

    &__icon-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(var(--site-navigation-panel-width) - (var(--padding-horizontal) * 2));

      &--profile {
        --size: 3rem;
        width: var(--size);
        height: var(--size);
        min-width: var(--size);
        border-radius: 555rem;
        overflow: hidden;
        background: #fff;
        margin-right: 0.75rem;
      }
    }

    &__icon {
      &--mdi {
        color: #fff;
        font-size: 19px;
        opacity: 0.8;
      }
    }

    .svg-icon {
      // --size: 2.6rem;
      // height: calc(0.95 * var(--size));
      // padding: 0.75rem;
      height: 32px;
      width: 41px;
      border-radius: 4px;
      // background: rgba(120, 84, 247, 0.05);
      transition: background 250ms ease-in-out;

      &__image {
        height: 18px;
        width: 18px;
      }
    }

    &__title {
      display: inline-block;
      max-width: 0;
      opacity: 0;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 500;
      vertical-align: text-bottom;
      color: #111827 !important;

      // text-transform: uppercase;
      transition: max-width 10ms linear, opacity 100ms ease-in-out;

      .open-icon{
        height: 8px;
        display: flex;
        align-items: center;
        margin-left: 85px;
      }

      &--brand-name {
        // font-size: 13px;
        position: absolute;
        right: 0.7rem;
        top: 1.88rem;
        font-weight: 700 !important;
      }
    }



    &--profile {

      .svg-icon {
        background: none !important;
      }

      .nav-link__icon {
        // --size: 75%;
        --size: 101%;
        width: var(--size);
        height: var(--size);
        object-fit: contain;
      }
    }

    &:hover,
    &:focus,
    &.active {
      opacity: 1;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      outline: none !important;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 5px;
      background: var(--color-theme-primary);
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &.active {
      background: var(--color-primary-blue-light);
      border-radius: 4px;
.svg-icon__image{
  filter: brightness(0) saturate(100%) invert(68%) sepia(88%) saturate(325%) hue-rotate(144deg) brightness(95%) contrast(96%);
  // filter:brightness(0) saturate(100%) invert(40%) sepia(100%) saturate(411%) hue-rotate(185deg) brightness(90%) contrast(104%);
}
      .nav-link__title {
        color: #50D7ED !important;
      }

      .svg-icon {
        // background: rgba(120, 84, 247, 0.05);
        background: unset;
      }
    }

    &.active:before {
      transform: scaleX(1);
    }
  }
}

.divider-nav {
  height: 1px;
  background: rgba(17, 24, 39, 0.10);

  margin-bottom: 24px;
}

:focus {
  outline: none !important;
}
.poweredBy{
  // margin-bottom: 8px;
  font-weight: 400 !important;
  font-style: italic !important;
  font-size: 10px !important;
}
.Zigram{
  font-weight: 700 !important;
  font-style: normal;
  font-size: 20px;
}
.logoutButton{
  position: absolute;
  bottom: 20px;
}
