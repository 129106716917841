.authorization-layout {
  --footer-height: 56px;

  // @media screen and (max-width: 1500px) {
  //   --footer-height: 50px;
  // }

  &__main {
    height: 100vh
  }

}