.homeContainer {
  .card {
    border: 1px solid #c2c8d1;
    border-radius: 4px;
    padding: 30px 16px;
    height: 160px;
    width: 100%;
  }

  .card-hover-shadow {
    transition: 0.3s;
  }

  .card-hover-shadow:hover {
    box-shadow: 0 6px 12px rgba(140, 152, 164, 0.25) !important;
  }

  .card-body {
    padding: 0;
  }

  .h-btn-container {
    .v-icon {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 20px;
        width: 20px;
      }

      &.active {
        background-color: #f0e7f3;
      }
    }
  }

  .project-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;

    .icon-name-container {
      // border-bottom: 1px solid #e8e8ea;


      .name-container {
        width: 170px;
      }

      @media (min-width:720px) and (max-width:1200px) {
        .name-container {
          width: 190px;
        }
      }

      @media (min-width:1301px) and (max-width:1500px) {
        .name-container {
          width: 210px;
        }
      }

      @media (min-width:1920px) and (max-width:2560px) {
        .name-container {
          width: 190px;
        }
      }

      .text-color-2 {
        color: #a0a3a9
      }

      .text-color {
        color: #111827;
      }

      .text-wrap-new {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .p-logo-container {
    height: 50px;
    width: 50px;
    padding: 5px;
    border: 1px solid #cfd1d4;
    display: flex;
    align-items: center;

    img {
      width: 40px;
      max-height: 40px;
    }
  }


  .hideIcon {
    width: 20px;
    height: 20px;
  }

  .showIcon {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .homeDate {
    font-weight: 600;
    color: var(--color-primary-blue);
  }

  .fa-edit {
    position: absolute;
    right: 20px;
    top: 10px;
    color: var(--color-primary-blue);
  }

  .fa {
    color: var(--color-primary-blue);
    cursor: pointer;
  }

  .customedit {
    width: 17px;
    height: 17px;
    vertical-align: baseline;
  }

  .bannerHome {
    position: relative;
    background: linear-gradient(90deg, #50D7ED 11.54%, #B166C9 84.3%);
    border-radius: 4px;
    margin-left: 0px !important;
  }

  .homeTextCss {
    margin-right: 50px;
  }

  .upperEllipse {
    position: absolute;
    top: 0;
    right: 420px;
  }

  .downLeftEllipse {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .downRightEllipse {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .smallEllipse {
    position: absolute;
    left: 200px;
    top: 0;
  }

  .HomeProjectHov {
    position: relative;
    height: auto;
    width: auto;
  }

  .HomelistProjectHov {
    display: none;
    z-index: -10;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
  }

  .HomeProjectHov:hover>.HomelistProjectHov {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333030;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    z-index: 999;
    display: flex;
    bottom: 35px;
    opacity: 1;
    border: 0.8px solid #333030;
    padding: 5px 10px;
  }

  .HomeProjectHov .HomelistProjectHov::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 100%;
    border-width: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #333030;
  }

  .HomeCaseScanInfo {
    span {
      font-weight: 400;
      color: #04021C;
    }
  }

  .HomeCaseScanInfo {
    position: relative;
    height: auto;
    width: auto;
    background: #E6CCEF;
    padding: 2px 12px;
    border-radius: 4px;
    border: 1px solid #EEDCF4 !important;
  }

  .HomeCaseScanInfoHov {
    display: none;
    z-index: -10;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
  }

  .HomeCaseScanInfo:hover>.HomeCaseScanInfoHov {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333030;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    z-index: 6001;
    display: flex;
    top: 35px;
    opacity: 1;
    border: 0.8px solid #333030;
    padding: 5px 10px;
  }

  .HomeCaseScanInfo .HomeCaseScanInfoHov::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    margin-left: -5px;
    top: -5px;
    border-width: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #333030;
  }

  .bannerText {
    font-style: normal;
    color: white;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;

    span {
      font-weight: 400;
    }
  }

  .dateDay,
  .dateMonth {
    font-style: normal;
    color: white;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
  }

  .dateMonth {
    font-weight: 700;
  }

  .banner-inner-text {
    font-style: normal;
    color: white;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}

input[type="file"] {
  color: #71748d;
  background-color: #fff;
  border-color: #a7a7f0;
  outline: 0;
  // box-shadow: 0 0 0 0.2rem rgb(214 214 255 / 75%);
}

.projectEditImg {
  border-radius: 10px;
  display: block;
  cursor: pointer;
  //  box-shadow: 0 14px 28px rgb(0, 0, 0, .25), 0 10px 10px rgb(0, 0, 0, .22);
}

.loading-more-btn:focus {
  box-shadow: none;
}

.loading-more-btn:hover {
  color: var(--color-primary-blue);
  text-decoration: none;
}
.p-16px{
  padding: 0px 16px;
}
#Actions {
  // position: relative;
  position: absolute;
  right: 0px;
  top: 18px;
  z-index: 1;
  margin-bottom: 10px;
  cursor: pointer;
.hideIcon{
  filter: brightness(0) saturate(100%) invert(68%) sepia(88%) saturate(325%) hue-rotate(144deg) brightness(95%) contrast(96%);
}
.ActiveDot{
  height: 11px ;
  width: 11px;
  background-color: #14804a;
  border-radius: 50%;
}
.InactiveDot{
  height: 11px ;
  width: 11px;
  background-color: #838488;
  border-radius: 50%;
}
.activeDiv{
  font-size: 13px;
  font-weight: 600;
}
  img {
    height: 18px;
    width: 18px;
   
  }

  .texM {
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .texM:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }


  .selectionBox {
    position: absolute;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    z-index: 999;
    right: 0px;
    width: 150px;
    word-break: break-all;
    display: none;
    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.25);
  }

  a {
    font-weight: normal;
  }

  li {
    cursor: pointer;
    text-align: left;
    font-weight: normal;
    padding: 8px;
    color: #1F2A55;
    font-size: 15px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  li:hover {
    background-color: var(--color-primary-blue-light);
  }

  .actionItems:hover {
    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }
}

// #Actions:hover .selectionBox{
// display: block;
// }
.btn.focus,
.btn:focus {
  box-shadow: none;
}

#Actions .sq {
  width: 20px;
  height: 21px;
  text-align: center;
  border-radius: 6px;
  padding-top: 3px;
}

#Actions .sq:hover {
  background: #e9e9e9;
}

.LoaderCenterAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // min-height: 100vh;
  min-height: calc(100vh - 250px);
}


.projectOptIcon {

  width: 20px;
  height: 8px;
}

.projectOptIcon:active {
  box-shadow: 1px 1px 9px 2px grey;
}

.SerialNumberWidth {
  width: 80px;
}

.RightArrowIconProject {
  font-size: 14px;
  color: #50D7ED;
}

.MinWidthIcon {
  width: 30px;
}

.InfoHov {
  .ProjectHide {
    top: 24px !important
  }
}


@media screen and (max-width: 1500px) {
  .homeContainer {
    .card {
      height: 156px;
    }
  }
}
.hideIcon{
  filter: brightness(0) saturate(100%) invert(68%) sepia(88%) saturate(325%) hue-rotate(144deg) brightness(95%) contrast(96%);
}

#addProject{
    min-width: min-content;
    .modal-content .modal-body {
        padding: initial;
      }
  
    .modal-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
      /* identical to box height, or 28px */
      color: #111827;
    }
  
    .modal-header {
      border: none;
    }
  
    .close {
      font-weight: 500 !important;
      opacity: 1;
      span{
        background-color: #F0F0F0;
        border-radius: 50%;
        color: #111827;
  
        // padding: 5px;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ImageUpload{
      height: 44px !important;
    }
    .drag-drop{
      height: 44px !important;
    }
    .uplaodImageName{
      overflow: hidden;
      // text-align: center;
      text-overflow: ellipsis;
      width: 160px;
    }
    .CancelImageUpload{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #e0d8fe;
      font-size: 12px;
      font-weight: 700;
      color: #111827;
    }
  
    @media screen and (max-width: 1500px) {
      .modal-title{
        font-size: 18px;
      }
      .ImageUpload{
        height: 38px !important;
      }
      .drag-drop{
        height: 38px !important;
      }
  }
}