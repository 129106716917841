.uploadBtnListing {
  position: absolute;
  right: 15px;
}

#fileUpload{
  .optionBox{
    color: #50D7ED;
  }
}

#batchupload {
  .FilterOptions {
    width: 121px;
  }

    // For active filter selection

.activeSelected{
  .picky__input {
    border-color: #50D7ED !important;
    color: #50D7ED !important;

    .picky__placeholder {
      color: #50D7ED !important;
    }
  }
  .picky__input::after{
    color: #50D7ED !important;
  }
} 

.CommonDateCalendar1 {
.btn-primary {
  border-color: #50D7ED !important;
}
.btn-primary {
  padding: 10px 14px !important;
}

.date-range-picker__toggle-button{
  border-radius: 6px !important;
}

 .date-range-picker__toggle-button__text{
color: #50D7ED !important;
}
}

  .picky__dropdown .option:hover {
    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }

  .picky__dropdown .option:focus {
    outline: none !important;
  }

  .scrhBarSize {

    .picky__input:focus {
      border-color: #50D7ED;
      color: #50D7ED;
    }

    .picky__input span {
      display: block !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      max-width: 90px !important;
      text-overflow: ellipsis !important;
    }

    .picky__dropdown .option {
      padding: 0.5rem 0.5rem;
      font-size: 14px;
      border-bottom: none;
    }

    .picky__dropdown .option input {
      // top: 5px;
      margin-right: 8px;
    }
// .font-15{
//   font-size: 15px!important;
// }
    .picky__input:after {
      width: 5px !important;
      height: 5px !important;
      border-left: 1px solid #595959 !important;
      border-bottom: 1px solid #595959 !important;
      transform: rotate(-45deg);
      position: absolute;
      margin-left: 2px;
      vertical-align: middle;
      font-family: FontAwesome!important;
      content: ""!important;
      border-top: none;
      border-right: none;
      // border-left: none;
      right: 8px !important;
      top: 16px;
    }

    .picky__input {
      overflow: hidden;
      width: 100%;
      height: 44px;
      padding: 0 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #111827;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 6px;
    }
  }

  .picky__dropdown {
    width: 240px;
    margin-top: 10px;
    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.08);
    // border: 1px solid rgba(17, 24, 39, 0.2);
    padding: 5px;
    border-radius: 6px;
    z-index: 98;
  }

  .picky__dropdown::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
  }

  .picky__filter__input{
    font-size: 14px;
  }

  /* Track */
  .picky__dropdown::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  .picky__dropdown::-webkit-scrollbar-thumb {
    background: #C5C4C8;
    border-radius: 10px;
  }

  .picky__dropdown::-webkit-scrollbar-thumb:hover {
    background: #C5C4C8;
  }

  .picky__placeholder {
    text-overflow: ellipsis;
  }

  // }
}

.pageDropdown {

  select,
  input {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 12px;
    font-weight: 600 !important;
  }
}

.uploadForm {
  .date-picker__container {
    max-width: 100%;
    height: 35px;
    border: 1px solid #d0d5d8;
    border-radius: 6px;
  }

  .date-picker__container:hover {
    max-width: 100%;
    box-shadow: none;
    height: 33px;
    box-shadow: 0 0 0 0.1rem #d7e4ff;
  }

  .MuiFormControl-marginNormal:hover {
    border: none !important;
    outline: none !important;
  }

  .form-control {
    margin-top: 4px !important;
  }

  // .date-range-picker button {
  //   padding: 0;
  //   padding-left: 5px;
  // }
}

.btn-Size {
  font-size: 12px;
  font-size: 500;
}

.DisableBatchBtn {
  background-color: #fafafb !important;
  border: 1px solid #fafafb !important;
  color: #6e727b !important;
}

.err-table {
  border-collapse: collapse;
  width: 100%;

  .head {
    background: var(--color-primary-blue);
    color: #fff;
  }

  &__row,
  &__cell {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-weight: 400;
  }
}

.upload-file {
  position: relative;

  .icon-container {
    position: absolute;
    right: 10px;
    bottom: 8px;

    .spinner-border {
      height: 20px;
      width: 20px;
      border-width: 3px;
    }
  }
}

.reRunBtn img {
  height: 15px;
}

.reRunBtn {
  background: none;
  border: none;
}

.batchUploadFormDiv {
  background-color: #fff;
  border: 1px solid #e9e9ec;
  border-radius: 8px;
}

.drag-drop {
  background: #E6FAFF !important;
  border: 2px dashed #50D7ED;
  border-radius: 6px;
  color: #04021c;
  max-width: none;
  min-width: 0;
  min-width: auto;
  width: 100%;

  .File-Upload {
    color: #111827;
    font-weight: 600;
  }

  p {
    color: #767888;
    font-weight: 400;
  }
}

.batch {
  display: flex;
  height: 120px;
  width: 100%;
}

.batch1 {
  display: flex;
  height: 95px;
  width: 100%;
}

.font-20 {
  font-family: "Open Sans";
  font-size: 20px;
  color: #111827;
  font-weight: 700;
}

.fileIcon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.CancelUpload {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: #e0d8fe;
  font-size: 16px;
  font-weight: 700;
  color: #111827;
}

.mr-1pt8 {
  margin-right: 1.8rem;
}

#schedulePopup {
  .btnscreen {
    width: 48%;
    background-color: #fff;
    border: 1px solid #6C4CDE;
    padding: 0.3rem 1rem;
    color: #6C4CDE;
    border-radius: 6px;

  }

  .btnscreen:hover {
    background-color: #6C4CDE;
    color: #fff;
    border: none;
  }
}

.uploadSchedule {
  margin-top: 30vh;
}

.uploadSchedule .modal-body {
  padding: 3rem 1rem 1.5rem 1rem;
}

.filterBatchTop{
  .picky__dropdown .option input {
    top: 0px !important;
    margin-right: 8px;
}
}

.BatchScanCalendar {
  .rdrDayInPreview,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border: none;
  }

  .date-range-picker__toggle-button {
    height: 44px !important;
    max-height: 44px !important;
    border-radius: 6px !important;
  }

  .btn-primary {
    padding: 10px 14px !important;
  }

  .date-range-picker__toggle-button__text {
    font-size: 14px !important;
    margin-left: 7px !important;
    color: #595959;
  }

  @media screen and (max-width: 1500px){
    .date-range-picker__toggle-button__text {
    font-size: 12px!important;
}
  }

  .custom-filter-wrap {
    position: inherit;
  }

  .rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 1000;
    right: inherit;
    width: 450px;
    max-width: 100vw;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  .rdrDayHovered {
    border: none;

    .rdrDayEndPreview {
      border: none;
      border-radius: 0px;
    }

    .rdrDayStartPreview {
      border: none;
      border-radius: 0px;
    }
  }

  .rdrInRange {
    background-color: #80E5FF !important;
  }

  .rdrSelected,
  .rdrStartEdge,
  .rdrEndEdge {
    background: #50D7ED !important;
    background-color: #50D7ED !important;
    border: 1px solid #50D7ED !important;
  }
  .rdrStartEdge{
    border-top-left-radius: 6px!important;
    border-bottom-left-radius: 6px!important;
  }
  .rdrEndEdge{
    border-top-right-radius:  6px!important;
      border-bottom-right-radius: 6px!important;
  }
  .rdrDayNumber {
    color: #111827;
    font-weight: 600;

    span {
      width: 100%;
    }

    ;

    span:after {
      height: 0% !important;
    }
  }

  .date-range-picker__toggle-button {
    height: 44px !important;
    max-height: 44px !important;
    border-radius: 6px !important;
  }

  .btn-primary {
    padding: 10px 14px !important;
  }

  .date-range-picker__toggle-button__text {
    font-size: 14px !important;
    margin-left: 7px !important;
    color: #595959;
  }

  .custom-filter-wrap {
    position: inherit;
  }

  .rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 1000;
    right: inherit;
    width: 450px;
    max-width: 100vw;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  .rdrDayInPreview,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border: none;
  }

  .rdrDayHovered {
    border: none;

    .rdrDayEndPreview {
      border: none;
      border-radius: 0px;
    }

    .rdrDayStartPreview {
      border: none;
      border-radius: 0px;
    }
  
    .rdrDayHovered{
      border: none;
      .rdrDayEndPreview{
        border: none;
        border-radius: 0px;
      }
      .rdrDayStartPreview{
        border:none;
        border-radius: 0px;
      }
    }
  
    .rdrInRange{
      background-color: #50D7ED !important;
    }
    .rdrSelected,.rdrStartEdge, .rdrEndEdge {
      background: #50D7ED!important;
      background-color: #50D7ED !important;
      border:1px solid #50D7ED!important;
      // border-radius: 6px!important;
    }
    .rdrStartEdge{
      border-top-left-radius: 6px!important;
      border-bottom-left-radius: 6px!important;
    }
    .rdrEndEdge{
      border-top-right-radius:  6px!important;
        border-bottom-right-radius: 6px!important;
    }
    .rdrDayNumber{
    color: #111827;
    font-weight: 600;

    span {
      width: 100%;
    }

    ;

    span:after {
      height: 0% !important;
    }
  }
}

.scheduleData {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
}
.InProgressTag {
  color: #D6B81F !important;
  background: #FBF6DD !important;

  .StatusDisplayText {
    color: #D6B81F !important;
  }

  .bg-status {
    background-color: #f5ca99 !important;
  }
}

.CompletedTag {
  color: #14804A !important;
  background: #E1FCEF !important;

  .StatusDisplayText {
    color: #14804A !important;
  }

  .bg-status {
    background-color: #00c9a7 !important;
  }
}
.FailedTag{
  color: #ed4c78 !important;
  background: rgba(229,86,86,.1) !important;

  .StatusDisplayText {
    color: #ed4c78 !important;
  }

  .bg-status {
    background-color: #ed4c78 !important;
  }
}
.PendingTag{
  color: #aa5b00 !important;
  background: #fcf2e6 !important;

  .StatusDisplayText {
    color: #aa5b00 !important;
  }

  .bg-status {
    background-color: #aa5b00 !important;
  }
}
.CancelledTag{
  color: #838488 !important;
  background: #f1f2f6 !important;

  .StatusDisplayText {
    color: #838488 !important;
  }

  .bg-status {
    background-color: #838488 !important;
  }
}
.topBtn {
  position: relative;
  top: -4px;
}

.ActionButtonWidth {
  width: 50px;
}

.BatchUploadBtnPad {
  padding: 4px 7px 4px 7px!important;
  height: auto!important;
}

.batchUploadErrPopup {
  .preview-modal-table {
    padding: 25px !important;
    height: auto !important;
    min-height: 140px;
    max-height: 400px;
  }

  .NoCenterAlign {
    tr th {
      text-align: justify !important;
      padding: 0.75rem;
    }

    tr td {
      text-align: justify !important;
      padding: 0.75rem;
      line-height: 140%;
      vertical-align: middle;
    }
  }
}

.p48 {
  width: 48%;
}

.downloadSampleabs {
  width: 155px !important;
  left: -38% !important;
}
.downloadSampleabs::before{
  left: 77%!important;
}
.batch-upload-page {
  .InfoHovD {
    @extend .InfoHov;
  }

  .InfoHovD:hover>.listInfoHov {
    left: 0px;
  }

  .InfoHovD:hover>.listInfoHov::before {
    left: 95px;
  }
}


@media screen and (max-width: 1500px) {
  .BatchScanCalendar {
    .date-range-picker__toggle-button {
      height: 38px !important;
      max-height: 38px !important;
    }

    .date-range-picker__toggle-button__text {
      font-size: 12px !important;
    }
  }

  #batchupload {
    .scrhBarSize {
      .picky__input:after{
        top: 8px;
      }
      .picky__input {
        height: 38px;
        font-size: 12px;
      }
      .picky__dropdown .option {
        font-size: 12px;
      }
      .picky__filter__input{
        font-size: 12px;
      }
    }
    .picky__placeholder {
      font-size: 12px;
    }
  }
}
.StatusWidth{
  width: 200px;
}
.batchUploadColumns{
.actionItems{
  .Icons{
    // filter: brightness(0) saturate(100%) invert(57%) sepia(83%) saturate(5648%) hue-rotate(214deg) brightness(91%) contrast(92%);
  }
}
}
.upload-form{
  max-width: 700px;
  margin: 0px auto;
}

.InfoHov-BioView1{
  position: relative;
  cursor: pointer;
}
.listInfoHov-BioView1 {
  display: none;
  z-index: -10;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
}
.InfoHov-BioView1:hover>.listInfoHov-BioView1 {
  position: absolute;
  left: 5%;
  transform: translateX(-50%);
  background-color: #333030;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  z-index: 999;
  display: flex;
  top: -35px;
  opacity: 1;
  border: .8px solid #333030;
  padding: 5px 10px;
  text-wrap: nowrap;
}
.InfoHov-BioView1 .listInfoHov-BioView1::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 62%;
  margin-left: -5px;
  top: 29px;
  border-width: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333030;

}