#LogoComponent{
    height: 98%;
    .subHeading{
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    // .page-info{
    //     width: 67%;
    // }
    .ProductName{
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
    }
    .page-info__heading{
        text-transform: none;
        margin-bottom: 2.5rem;
    }
    .page-info__description{
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
    .upperEllipse{
        position: absolute;
        width: 320px;
        height: 320px;
        right: 0px;
        top: -100px;
        
    }
    .LowerEllipse{
        position: absolute;
        width: 158px;
        height: 158px;
        left: 0px;
        bottom: -50px;
        
    }
    @media (max-width:475px) {
        .subHeading{
            font-size: 25px;
        }
        .ProductName{
            font-size: 32px;
        }
    }
}