.tab-container {
  &__header {
    // padding: 0 1rem;
    border-bottom: 1px solid #d8d8d8;

    .tab-name {
      display: inline-block;
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: #999999;
      border-bottom: 2px solid transparent;
      transition: color 150ms ease-in-out;

      &.active {
        color: var(--color-primary-blue);
        border-color: var(--color-primary-blue);
        font-weight: 500;
      }
    }
  }

  &__content {
    padding: 24px 0px;
  }
}
