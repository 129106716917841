@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

body {
  // font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  //   "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  //   "Helvetica Neue", sans-serif;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  background-color: #fff;
  //color: red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Open Sans";
}

// -----------------| HEADINGS |------------------
.app-h1 {
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0.53px;
}

.app-h2 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.49px;
}

.app-h3 {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.45px;
}

.app-h4,
.app-h5,
.app-h6 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.41px;
}

// -----------------| PARAGRAPHS |------------------
.app-p-xs {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.24px;
}

.app-p-sm {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.27px;
}

.app-p-md {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.29px;
}

.app-p-lg {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.31px;
}

.app-p-xl {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.33px;
}

// -----------------| MIXINS |------------------
@mixin heading-styling {
  color: var(--color-primary-blue);
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: -0.13px;
}

@mixin sub-heading-styling {
  color: #999999;
  font-size: 14px;
  letter-spacing: -0.1px;
}

// -----------------------| TEXT OVERFLOW ELLIPSES |-------------------------
@mixin truncate-overflow-ellipses(
  $max-lines: 1,
  $line-height: 1rem,
  $color-ellipses-bg: var(--color-gray-light),
  $color-ellipses: #000
) {
  position: relative;
  max-height: $line-height * $max-lines;
  width: min-content;
  max-width: 100%;
  overflow: hidden;
  padding-right: 1rem !important; /* space for ellipsis */

  &:before {
    position: absolute;
    bottom: 0;
    right: 0.5rem;
    z-index: 200;
    content: "...";
    color: $color-ellipses;
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0.5rem;
    z-index: 100;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: $line-height;
    background: $color-ellipses-bg;
  }
}
