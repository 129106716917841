.react-bootstrap-table thead th {
  color: #fff;
  background-color: #50D7ED;
  font-size: 12px;
  font-weight: 500;
  border: none;
}
  #userManagment #single-select-dp {
    width: 300px;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table thead tr {
  background: #e9e9f1;
}

.react-bootstrap-table tr td {
  color: #666;
  line-height: 1.1;
  font-size: 12px;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color:  var(--color-primary-blue);
  border-color: var(--color-primary-blue);
}

// .modal-details-popup {
//   max-width: calc(50vw - 1rem) !important;
// }

.modal-title {
  color: #50D7ED;
}

.schemaView {
  display: inline-block;
  // float: right;
  margin-right: 10px;
}

.schemaView p {
  color: #50D7ED;
  font-size: 12px;
  font-weight: 600;
  float: right;
}

//ProjectSchema Page
#a_unique_id-body,
#b_unique_id-body {
  border: 1px solid #d0d5d8;
  width: 100%;
  background-color: #fcfdfd;
}

#a_unique_id-body:hover,
#b_unique_id-body:hover {
  box-shadow: 0 0 0 0.1rem #d7e4ff;
}

#a_unique_id-outer-box {
  width: auto !important;
}

.pageDropdown {
  #pageDropDown {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-size: 12px;
    font-weight: 600 !important;
  }

  #pageDropDown:focus {
    box-shadow: none !important;
  }

  .dropdown-menu {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-width: 1rem !important;
    z-index: 100;
  }

  a {
    font-weight: normal;
    font-size: 14px;
  }
}

#UserListTab .search-label,
#roleTabTable .search-label,
.scrhBarSize .search-label {
  width: 75%;
}

#UserListTab {
  padding: unset;
}

#RoleListTab button {
  margin-top: 1.7rem;
}


.add-user-div {
  color: #50D7ED;
  border: 1px solid #50D7ED;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 24px;
  height: 44px;
}



.add-user-div-dropdown,
.status-div-dropdown {
  position: absolute;
  width: 200px;
  background-color: white;
  border: 1px solid rgba(17, 24, 39, 0.2);
  border-radius: 8px;
}

.add-user-div-dropdown {
  right: 0;
}


.add-user-div-dropdown-content,
#AddExistingUserModal .search-result-item {
  color: #00000066;
  cursor: pointer;
  // height: 45px;
  display: flex;
  align-items: center;


  &:hover {
    // background-color: red;
    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }
}



#UserListTab .headerClasses-userlist {
  background-color: #F9FAFD;
  border-radius: 10px;
}

.left-p-unset {
  padding-left: unset !important;
}


.user-management-info-card {
  min-width: 250px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  padding: 15px 15px 15px 24px;


  &>div {
    color: rgba(17, 24, 39, 0.8);
    font-weight: 400;
    font-size: 14px;
  }


  &>p {
    font-weight: 600;
    font-size: 16px;
    color: #111827;
  }
}

.invite-user-select-role>option {

  background-color: white;
  color: rgba(17, 24, 39, 0.8);


  &:hover {
    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }

}




.custom-modal-title {
  &>h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #111827;
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;

  }

  &>span {
    color: rgba(17, 24, 39, 0.6);
    cursor: pointer;
    font-size: 20px;
  }
}



.bulkupload-modal-details-popup {
  max-width: calc(55vw - 1rem);
  z-index: 1;
  min-height: 60vh;
}



#Actions-1 {
  // position: relative;
    border-radius: 4px;
    margin-left: 8px;
    background-color: #E6FAFF;
    width: 24px;
    height: 24px;
    cursor: pointer;

  .disabledButton {
    cursor: not-allowed;

  }
  #dropdownMenuButton {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    rotate: 90deg;
}

  .selectionBox {
    // position: absolute;
    background: #fff;
    border-radius: 5px;
    z-index: 999;
    right: 80px;
    min-height: 50px;
    min-width: 228px;
    // padding:10px;
    // top: 20px;
    word-break: break-all;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 6px 2px;
    border: 1px solid rgba(17, 24, 39, 0.2);
  }

  .selectionBox-2 {
    background: #fff;
    border-radius: 5px;
    z-index: 999;
    right: 65px;
    min-height: 50px;
    min-width: 175px;
    // padding:10px;
    word-break: break-all;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 6px 2px;
    border: 1px solid rgba(17, 24, 39, 0.2);
  }
  .actionItems{
    .Icons{
      // filter: brightness(0) saturate(100%) invert(57%) sepia(83%) saturate(5648%) hue-rotate(214deg) brightness(91%) contrast(92%);
    }
  }
  .actionItems {
    a {
      display: block;

    }

    p {
      padding: 13px 8px;
      display: flex;
      align-items: center;
    }

    cursor: pointer;
    text-align: left;
    padding: 0px;
    color: #1F2A55;
    font-size: 15px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;

    /* Standard syntax */
    a {
      cursor: pointer;
      text-align: left;
      color: #1F2A55;
      font-size: 15px;
      -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;
      font-weight: 400;
      /* Standard syntax */
    }
  }

  .actionItems:hover {
    a {
      color: #50D7ED;
    }

    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }
}

#Actions-2 {
  // position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .disabledButton {
    cursor: not-allowed;

  }
  #dropdownMenuButton{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selectionBox {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    z-index: 99;
    right: 80px;
    min-height: 50px;
    min-width: 175px;
    // padding:10px;
    word-break: break-all;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 6px 2px;
    border: 1px solid rgba(17, 24, 39, 0.2);
  }

  .selectionBox-2 {
    background: #fff;
    border-radius: 5px;
    z-index: 999;
    right: 65px;
    min-height: 50px;
    min-width: 175px;
    // padding:10px;
    word-break: break-all;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 6px 2px;
    border: 1px solid rgba(17, 24, 39, 0.2);
  }

  .actionItems {
    a {
      display: block;
      font-weight: 400;
    }

    cursor: pointer;
    text-align: left;
    padding: 13px 8px;
    color: #1F2A55;
    font-size: 15px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;

    /* Standard syntax */
    a {
      cursor: pointer;
      text-align: left;
      color: #1F2A55;
      font-size: 15px;
      font-weight: 400;
      -webkit-user-select: none;
      /* Safari */
      -ms-user-select: none;
      /* IE 10 and IE 11 */
      user-select: none;
      /* Standard syntax */
    }
  }

  .actionItems:hover {
    a {
      color: #50D7ED;
    }

    color: #50D7ED;
    background: var(--color-primary-blue-light);
  }
}

// #Actions-2:hover .selectionBox-2 {
//   display: block;
// }

// #Actions-1:hover .selectionBox-2 {
//   display: block;
// }

#reassignRole {
  .UserInfo {
    border: 1px solid #DCDCDC;
    border-radius: 6px;
    margin-bottom: 2rem;

    .uName {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #111827;
    }

    .uMail {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #797979;
    }
  }
}

.bg-success-width {
  width: 7px;
  height: 7px;
  border-radius: 2px;
}

.Active {
  background: #E1FCEF;
  border-radius: 4px;
  width: fit-content;
}

.Inactive {
  background: rgba(229, 86, 86, 0.1);
  border-radius: 4px;
  width: fit-content;
}

.bg-danger-width {
  width: 7px;
  height: 7px;
  border-radius: 2px;
}

.color-Gray {
  color: #687182;
}

.uProfile {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.new_heading {
  font-size: 28px;
  font-weight: 600;
  color: #111827;
}

.sortable {
  .order-4 {
    // display: none;
  }
}

.shwo-no-text {
  .select-list-size button {
    height: 32px;
    width: 43px;
    padding: unset !important;
    font-size: 14px !important;
  }

  .dropup .dropdown-toggle::after {
    display: none !important;
  }
}

// .react-bootstrap-table-pagination-total {
//   visibility: hidden;
// }

.page-item {
  margin: 0 4px;
}

.page-item.active .page-link,
.page-link {
  height: 32px;
  width: 32px;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

li[title="next page"],
li[title="previous page"] {
  .page-link {
    font-weight: 600 !important;
    border-radius: 50% !important;
    background-color:  var(--color-primary-blue) !important;
    color: #fff !important;
  }
}


input[type="checkbox"] {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  color: var(--color-primary-blue);
  border-radius: 4px;
  border: 1.5px solid #d7dade;
  margin: 0 2px 0 0;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &:checked {
    color: #ffffff;
    background-color: var(--color-primary-blue);;
    border-color: var(--color-primary-blue);;

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 2px;
      left: 4.5px;
      width: 5px;
      height: 8px;
      border-style: solid;
      border-color: var(--color-white);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 1;
    }

    ~label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

input[type="checkbox"]:indeterminate {
  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 7px;
    left: 4px;
    width: 7px;
    height: 10px;
    border-style: solid;
    border-color: #50D7ED;
    border-width: 2px 0px 0px 0;
  }
}

.removeLabs {
  left: -7px;
}

.dp-l-icon {
  height: 15px;
  max-width: 15px;
}

@media screen and (max-width: 1500px) {
  .new_heading {
    font-size: 26px;
  }

  .add-user-div-dropdown-content,
  #AddExistingUserModal .search-result-item {
    height: 38px;
  }

  #Actions-1 {
    .actionItems {
      font-size: 14px;
    }
  }

  .add-user-div{
    height: 38px;
    font-size: 12px;
  }
}