.site-footer {
  height: var(--footer-height);
  display: flex;
  align-items: center;
  color:#72727d!important;
  background: #d9e2fa;
  z-index: 3;
  position: relative;

  .footer-link,
  .copyright-text {
    color: gray !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    line-height: 20px;
  }
  .FooterLogo{
    width: 130px;
    height: auto;
    opacity: 0.75;
      }
  .footer-link {
    padding: 0 0.5rem;
    border-right: 2px solid #72727d;
    color: gray !important;
    &:last-child {
      border-right: none;
    }
  }
 
}
