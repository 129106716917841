.picky {
    width: 100%;
    position: relative;
  }
  .picky * {
    box-sizing: border-box;
  }
  .picky__input {
    width: 100%;
    background-color: white;
    border: 0;
    border-width: 1px;
    border-color: #eee;
    border-style: solid;
    border-radius: 2px;
    padding: 5px;
    text-align: left;
    position: relative;
    box-sizing: border-box;
    height: 38px;
  }
  
  .picky__input--disabled,
  .picky__input--disabled + .picky__dropdown .option {
    color: rgb(95, 94, 94);
  }
  .picky__input::after {
    width: 5px !important;
    height: 5px !important;
    border-left: 1px solid #595959 !important;
    border-bottom: 1px solid #595959 !important;
    transform: rotate(-45deg);
    position: absolute;
    margin-left: 2px;
    vertical-align: middle;
    font-family: FontAwesome!important;
    content: ""!important;
    border-top: none !important;
    border-right: none !important;
    // border-left: none;
    right: 8px !important;
    top: 16px;
  }
  .picky__dropdown {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background: white;
    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.08);
    // border-left: 1px solid #eee;
    // border-right: 1px solid #eee;
    // border-bottom: 1px solid #eee;
    top: 39px;
  }
  .picky__dropdown .option,
  .picky__dropdown li {
    flex-wrap: revert;
    display: flex;
    align-items: center;
    list-style: none;
    background-color: white;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
    padding: 0.5em;
    cursor: pointer;
    border-bottom: none!important;
    text-align: left;
  }
  .picky__dropdown .option:hover,
  .picky__dropdown li:hover {
    color: #50D7ED;
    background: rgba(120, 84, 247, 0.05);
    .CustomDropdown{
      color: #50D7ED!important;
    }
  }
  .picky__dropdown .option.selected,
  .picky__dropdown li.selected {
    color: #50D7ED;
    background:  var(--color-primary-blue-light);
    .CustomDropdown{
      color: #50D7ED!important;
    }
  }
  .picky__dropdown .option:focus,
  .picky__dropdown li:focus {
    outline:none !important;
  }
  .picky__dropdown .option input[type="checkbox"],
  .picky__dropdown .option input[type="radio"],
  .picky__dropdown li input[type="checkbox"],
  .picky__dropdown li input[type="radio"] {
    margin-right: 8px!important;
  }
  .picky__filter {
    display: flex;
    width: 100%;
    padding: 3px;
  }
  .picky__filter__input {
    width: 100%;
    padding: 3px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    height: 30px;
  }
  
  .picky__placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #111827;
  }
  .deleteBatchPopup{
    .modal-body{
      padding: 1.5rem 1rem 1.5rem 1rem!important;
    }
    .modal-title{
      font-size: 16px!important;
    }
  }