#single-select-dp {
  
    height: 44px;
    font-size: 14px;
    .custom-search-container {
        height: 35px;
    }
    .NewInput{
        height: 35px;
    }
    .searchInputNew {
        height: 44px !important;
    }
    .searchDropdown{
        top:45px!important
    }
    .paddingbutton{
        padding: 12px 20px;
    }
    .status-div-dropdown {
        position: absolute;
        left: 0;
        top: 38px;
        width: 300px;
        background-color: white;
        border: 1px solid rgba(17, 24, 39, 0.2);
        border-radius: 8px;
        max-height: 300px;
        overflow-y: auto;
        z-index: 1000;

        .add-user-div-dropdown-content {
            color: #1F2A55;
            cursor: pointer;
            font-size: 14px;
            white-space: normal !important;

            p {
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                line-height: 1.3;
            }

            &:hover {
                // background-color: red;
                color: #50D7ED;
                // background: rgba(120, 84, 247, 0.05);
            }
        }
    }

    .status-div-dropdown::-webkit-scrollbar {
        width: 7px;
        border-radius: 20px;
    }

    /* Track */
    .status-div-dropdown::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    .status-div-dropdown::-webkit-scrollbar-thumb {
        background: #C5C4C8;
        border-radius: 10px;
    }

    .status-div-dropdown::-webkit-scrollbar-thumb:hover {
        background: #C5C4C8;
    }

    @media screen and (max-width: 1500px) {
        // height: 38px;
        // font-size: 12px;
        // padding: 12px 15px;

        .searchInputNew {
            height: 38px !important;
        }
    }

}

#single-select-dp.disabledButton {
    cursor: not-allowed !important;
    background-color: #e9ecef !important;
    opacity: 1 !important;
    pointer-events: none !important;
}